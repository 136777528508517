import Card from "../components/cards/Card";
import { DefaultPageLayoutOpen } from "../components/layout/LayoutDefaultPageOpen";
import styled from "styled-components";
import { CardTitle } from "../components/cards/CardTitle";
import React, { useContext, useEffect, useState } from "react";
import {
  errorNotification,
  infoNotification,
  warningNotification,
} from "../components/notifications/Notifications";
import { useParams } from "react-router-dom";
import { Avatar, Checkbox, Switch, Table } from "antd";
import { CardContent } from "../components/cards/CardContent";
import { InputFieldTitle } from "../components/form/InputFieldTitle";
import { InputFieldWrapper } from "../components/form/InputFieldWrapper";
import { ReactComponent as MicEnabled } from "../assets/icons/microphone-2.svg";
import { ReactComponent as MicDisables } from "../assets/icons/microphone-slash.svg";
import { ReactComponent as CameraEnabled } from "../assets/icons/video.svg";
import { ReactComponent as CameraDisables } from "../assets/icons/video-slash.svg";
import { AuthContext } from "../providers/AuthContextProvider";

export const OnlineUserList = ({ socket }) => {
  const [stateLoaded, setStateLoaded] = useState(false);
  const { room } = useParams();
  const { user } = useContext(AuthContext);

  const [userList, setUserList] = useState([]);
  const columns = [
    {
      title: "socket ID",
      dataIndex: "id",
      key: "id",
    },
    // { title: 'userUuid',
    //   dataIndex: 'userUUID',
    //   // key: 'userUUID',
    //   // sorter: (a, b) => a.eventName.length - b.eventName.length,
    //   // sortDirections: ['descend'],
    // },
    // {
    //   title: 'room',
    //   dataIndex: 'room',
    //   render: (item) => (
    //       <>
    //         <div>
    //           {item === null ? '' : item}
    //         </div>
    //       </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "avatar",
      width: 82,
      key: "avatar",
      render: (text, user) => (
        <Avatar
          shape="square"
          size={50}
          src={user.state.userDetails.profileImageUrl}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "state",
      render: (item) => (
        <>
          <div>{item === null ? "" : item.userDetails.firstName}</div>
        </>
      ),
    },

    {
      title: "Raised hand",
      dataIndex: "state",
      render: (item) => <>{item.raisingHand ? <>✋</> : <></>}</>,
    },
    // {
    //   title: 'Muted',
    //   // dataIndex: 'id',
    //   key: 'actions',
    //   render: (item) => (
    //       <>
    //         <Switch checked={item.state.muted} onChange={(value) => {
    //           // handleOnMuteSingleUser(item.id, value);
    //
    //
    //         }}/>
    //       </>
    //   ),
    // },
    {
      title: "Allow to use microphone with audio-mute-all",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          <Switch
            checked={item.state.overrideMuteEveryone}
            onChange={(value) => {
              handleAllowMicSingleUser(item.id, value);
            }}
          />
        </>
      ),
    },
    {
      title: "Mic status",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          {item.state.muted === false ? (
            <div
              onClick={(value) => {
                handleMuteMicSingleUser(item.id, value);
              }}
            >
              <MicEnabled></MicEnabled>
            </div>
          ) : (
            <MicDisables></MicDisables>
          )}
        </>
      ),
    },
    {
      title: "Allow to use webcam with video-mute-all",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          <Switch
            checked={item.state.overRideVideoMuteEveryone}
            onChange={(value) => {
              handleAllowWebCamSingleUser(item.id, value);
            }}
          />
        </>
      ),
    },
    {
      title: "Cam status",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => (
        <>
          {/*{item.state.videoMuted === false ?  <CameraEnabled></CameraEnabled> : <CameraDisables></CameraDisables>}*/}
          <>
            {item.state.videoMuted === false ? (
              <div
                onClick={(value) => {
                  handleMuteCamSingleUser(item.id, value);
                }}
              >
                <CameraEnabled></CameraEnabled>
              </div>
            ) : (
              <CameraDisables></CameraDisables>
            )}
          </>
        </>
      ),
    },

    {
      title: "Current state",
      // dataIndex: 'id',
      key: "actions",
      render: (item) => <>{item.state.currentState}</>,
    },
  ];

  const [roomState, setRoomState] = useState({
    allowSeating: false,
    forcedSeats: false,
    lightsOn: false,
    movementDisabled: false,
    muteEveryone: false,
    muteVideoEveryone: false,
    raiseHandEnabled: false,
    showNameTags: true,
    userOnStage: "",
  });

  const adminConnectionDetails = {
    room: room,
    password: "levaR",
  };

  useEffect(() => {
    socket.emit("admin", adminConnectionDetails);
    socket.on("admin-result", handleAdminResult);
    socket.on("state", (state) => {
      updateState(state);
    });
    socket.on("update", (state) => {
      updateState(state);
    });
    socket.on("users", (users) => {
      setupUsers(users);
    });
    return () => {
      socket.off("admin-result");
      socket.off("state");
      socket.off("update");
      socket.off("users");
    };
  }, [socket]);

  function handleAllowMicSingleUser(id, value) {
    // if(value === true){
    const data = {
      userState: {
        overrideMuteEveryone: value,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
    // } else{
    //   warningNotification("It's not allowed to enable the mic for a single user")
    // }
  }

  function handleAllowWebCamSingleUser(id, value) {
    // if(value === true){
    const data = {
      userState: {
        overRideVideoMuteEveryone: value,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
    // } else{
    //   warningNotification("It's not allowed to enable the mic for a single user")
    // }
  }

  function handleMuteMicSingleUser(id, value) {
    const data = {
      userState: {
        muted: true,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
  }

  function handleMuteCamSingleUser(id, value) {
    const data = {
      userState: {
        videoMuted: true,
      },
      id: id,
      room: room,
    };
    socket.emit("updateUserStateByAdmin", data);
  }

  function handleMuteAllUsers(e) {
    warningNotification("Mute all users");
    const data = {
      action: "muteEveryone",
      actionValue: e,
      room: room,
    };
    socket.emit("action", data);
    roomState.muteEveryone = e;
    //TODO maybe update state of mute a singel user when force mute all
    // userList.filter(user => user.state.muted === false).forEach(user => {
    //   socket.emit("toggleMute", { id: user.id, muted: e })
    // })
  }

  function handleMuteVideoAllUsers(e) {
    warningNotification("Mute all users");
    const data = {
      action: "muteVideoEveryone",
      actionValue: e,
      room: room,
    };
    socket.emit("action", data);
    roomState.muteVideoEveryone = e;
    //TODO maybe update state of mute a singel user when force mute all
    // userList.filter(user => user.state.muted === false).forEach(user => {
    //   socket.emit("toggleMute", { id: user.id, muted: e })
    // })
  }

  function handleAdminResult(response) {
    const data = {
      room: room,
    };
    socket.emit("getUsers", data);
    socket.emit("state", data);
  }

  function updateState(state) {
    setRoomState(state);
  }

  function setupUsers(user) {
    setUserList(user);
  }

  const renderModOptions = () => {
    if (!stateLoaded) {
      return <></>;
    }
    return <></>;
  };

  return (
    <>
      <Card>
        <CardHeaderWrapper>
          <CardTitle>Host in room: {room}</CardTitle>
          <InputFieldWrapper>
            <InputFieldTitle>Mute mic everyone </InputFieldTitle>
            <ActionContainer>
              <Switch
                checked={roomState.muteEveryone}
                onChange={() => {
                  handleMuteAllUsers(!roomState.muteEveryone);
                }}
              />
            </ActionContainer>
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Mute cam everyone </InputFieldTitle>
            <ActionContainer>
              <Switch
                checked={roomState.muteVideoEveryone}
                onChange={() => {
                  handleMuteVideoAllUsers(!roomState.muteVideoEveryone);
                }}
              />
            </ActionContainer>
          </InputFieldWrapper>
        </CardHeaderWrapper>
        <CardContent>
          <TableContainer>
            <Table
              dataSource={userList}
              columns={columns}
              rowKey={(data) => data.socketId}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

const TableContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ActionContainer = styled.div`
  //display: flex;
  margin-left: var(--space-40);
  display: inline-flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
`;

const CardHeaderWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  hight: 100%;
  width: 100%;

  gap: 7px;
`;

const CustomButton = styled.button`
  font-family: "DMSans";

  color: #ffffff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #4318ff;
  border: 0px solid #000000;
  border-radius: 120px;
  margin: 24px 0px;

  :hover {
    background: #2200b7;
    color: #ffffff;
    border: 0px solid #000000;
    transition: 0.3s all;
  }

  :active {
    transition: 0.3s all;
    transform: translateY(3px);
    opacity: 0.8;
  }
`;

const CustomButtonDisabled = styled.button`
  color: #4318ff;
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 7px;
  width: 270px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #4318ff;
  border-radius: 120px;
  margin: 10px 0px;

  &:hover {
    border: 1px solid #2200b7;
    color: #2200b7;
  }
`;

const ButtonTextOS = styled.div`
  font-family: "DMSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
`;
