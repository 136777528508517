import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import LogOut from "../modal/ModalLogOut";
import PairDevice from "../modal/ModalPairDevice";
import { ButtonTextStyles } from "../../styles/ButtonTextStyles";
import { FlaggedFeatureVideoStreaming } from "../../utils/DevCycleVideoStreaming";

function NavigationSettingsMenu({ children }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    function handler(event) {
      if (!modalRef.current?.contains(event.target)) {
        closeMenu();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  function closeMenu() {
    setOpen(false);
  }

  function handleMenuState() {
    if (open) {
      closeMenu();
    } else {
      setOpen(true);
    }
  }

  return (
    <>
      <div id="modal" ref={modalRef}>
        <div onClick={() => handleMenuState()}> {children}</div>
        <NavigationMenuContainer className={classNames({ "is-open": open })}>
          <NavigationList>
            <NavigationItem>
              <PairDevice />
            </NavigationItem>
            <FlaggedFeatureVideoStreaming>
              <NavigationItem>
                <button onClick={() => navigate("/streaming")}>
                  Streaming
                </button>
              </NavigationItem>
            </FlaggedFeatureVideoStreaming>
            <NavigationItem>
              <a
                className="NavBarLink"
                href="/creators"
                rel="noreferrer"
                onClick={() => setOpen(false)}
              >
                <ButtonText>Creators</ButtonText>
              </a>
            </NavigationItem>
            <NavigationItem>
              <a
                className="NavBarLink"
                href="https://docs.ravel.world"
                target={"_blank"}
                rel="noreferrer"
                onClick={() => setOpen(false)}
              >
                <ButtonText>Support</ButtonText>
              </a>
            </NavigationItem>
            <NavigationItem>
              <a
                className="NavBarLink"
                href="/integrations"
                rel="noreferrer"
                onClick={() => setOpen(false)}
              >
                <ButtonText>Integrations</ButtonText>
              </a>
            </NavigationItem>
            <NavigationItem>
              <LogOut />
            </NavigationItem>
          </NavigationList>
        </NavigationMenuContainer>
      </div>
    </>
  );
}

export default NavigationSettingsMenu;

const NavigationMenuContainer = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  max-height: 75vh;
  overflow-y: auto;
  transition: 0.3s;
  opacity: 1;
  right: calc(15%);
  top: calc(-265%);
  min-width: 180px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 90px 5px;
  border-radius: 18px;
  background-color: white;

  &.is-open {
    display: block;
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
  }
`;

const ButtonText = styled.button`
  ${ButtonTextStyles}
  min-width: 110px;
  min-height: 32px;
  font-size: 14px;
  line-height: 24px;
  text-align: start;
  justify-content: start;
`;

const NavigationList = styled.div`
  text-align: start;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const NavigationItem = styled.ul`
  padding: 0.3rem;
  transition: 0.3s all;
  transform: scale(0.95);

  &:hover {
    transform: scale(1);
    transition: 0.3s all;
  }
`;
