import styled from 'styled-components';

export const  ModalBody = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
`;
