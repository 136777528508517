import HeaderClosed from "../HeaderClosed";
import styled from "styled-components";
import NavigationBar from "../navigation/NavigationBar";
import { FooterClosed } from "../form/FooterClosed";
import DevCycle from '../../utils/DevCycle';

const PageSpecific = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LayoutDefaultPageClosed = ({ children }) => (
  <>
    <DevCycle>
    <HeaderClosed />
    <PageSpecific>{children}</PageSpecific>
    <NavigationBar />
    <FooterClosed />
    </DevCycle>
  </>
);
