import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import SubMenuBar from "../sub-menu/SubMenuBar";
import { errorNotification } from "../notifications/Notifications";
import { getSpacesWithParams } from "../../api/services/SpaceProServices";
import { SubMenuContainer } from "../sub-menu/SubMenuContainer";
import { SubMenuItem } from "../sub-menu/SubMenuItem";
import LoaderCircleBig from "../loader/LoaderCircleBig";
import SpacesCards from "./SpacesCards";
import { useParams } from "react-router-dom";
import { getEventDetails } from "../../api/services/EventServices";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import { TextExtraLarge } from "../Text";

export function SpacesEventSection() {
  const [fetching, setFetching] = useState(true);
  const { eventId } = useParams();
  const [spacesPublic, setSpacesPublic] = useState([]);
  const [eventDetails, setEventDetails] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await getSpacesWithParams("EVENT", "", eventId);
      if (response.data) {
        setSpacesPublic(response.data);
        setFetching(false);
      } else {
        setSpacesPublic([]);
        setFetching(false);
      }
    } catch (error) {
      setFetching(false);
      errorNotification("Spaces could not be loaded");
    }
  }, []);

  const fetchEventDetails = useCallback(async () => {
    try {
      const response = await getEventDetails(eventId);
      if (response.data) {
        setEventDetails(response.data);
      } else {
        setEventDetails([]);
      }
    } catch (error) {
      errorNotification("Event could not be loaded");
    }
  }, []);

  useEffect(() => {
    fetchEventDetails().then(() => {
      fetchData();
    });
  }, []);

  const renderSpaces = () => {
    if (fetching) {
      return <LoaderCircleBig></LoaderCircleBig>;
    }
    return (
      <>
        <SpacesCards dataToRender={spacesPublic} fetching={fetching} />
      </>
    );
  };

  return (
    <>
      <PageDetailsProvider
        title={eventDetails.eventName}
        description={""}
        meta={""}
      />
      <>
        <>
          <SubMenuContainer>
            <SubMenuBar>
              <SubMenuItem>
                <TextExtraLarge>{eventDetails?.eventName}</TextExtraLarge>
              </SubMenuItem>
            </SubMenuBar>
          </SubMenuContainer>
          <SpacesContainer>
            <SpaceOverwiew>
              <SpaceList>
                <GridContainer>
                  <SpaceGrid>{renderSpaces()}</SpaceGrid>
                </GridContainer>
              </SpaceList>
            </SpaceOverwiew>
          </SpacesContainer>
        </>
      </>
    </>
  );
}

const SpacesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SpaceOverwiew = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  @media (min-width: 1400px) {
    width: 60vw;
  }
`;

const SpaceList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const GridContainer = styled.div`
  height: 100%;
`;

const SpaceGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  row-gap: 40px;
  column-gap: 40px;
  width: 100%;
`;
