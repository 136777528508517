import { useForm } from "react-hook-form";
import React, { useState } from "react";
import styles from "../../components/form/DefaultForm.modules.css";
import { InputFieldWrapper } from "./InputFieldWrapper";
import { InputFieldTitle } from "./InputFieldTitle";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import { updateOrganizationRole } from "../../api/services/OrganizationUserServices";

function FormChangeOrganizationRole({
  userUuid,
  organizationName,
  closeModal,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onCLose = () => closeModal(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await updateOrganizationRole(
        organizationName,
        userUuid,
        data.organizationRole
      );
      if (response.status === 200) {
        setLoading(false);
        successNotification("Updated organization role");
        onCLose();
      }
      if (response.status === 404) {
        errorNotification(response.message);
        setLoading(false);
      }
      if (response.status === 400) {
        errorNotification(response.message);
        setLoading(false);
      }
      onCLose();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Role</InputFieldTitle>
            <select {...register("organizationRole")}>
              <option value="ORGANIZATION_GUEST">Guest</option>
              <option value="ORGANIZATION_USER">User</option>
              <option value="ORGANIZATION_ADMIN">Admin</option>
            </select>
          </InputFieldWrapper>

          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Change role"}{" "}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormChangeOrganizationRole;
