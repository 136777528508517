import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import { ButtonChip } from "../../components/button/ButtonChip";
import { ButtonTextDefault } from "../../components/button/ButtonTextDefault";
import { getOrganizationsForUser } from "../../api/services/OrganizationUserServices";
import {
  errorNotification,
  successNotification,
} from "../../components/notifications/Notifications";
import { assignEnvironmentToOrganization } from "../../api/services/EnvironmentServices";
import { AuthContext } from "../../providers/AuthContextProvider";
import { InputFieldWrapper } from "../../components/form/InputFieldWrapper";
import { InputFieldTitle } from "../../components/form/InputFieldTitle";
import styled from "styled-components";
import LoaderCircleSmall from "../../components/loader/LoaderCircleSmall";
import { ModalBody } from "../../components/modal/ModalBody";

function ModalManageAssigendOrganizations({
  environmentUuid,
  fetchEnvironmentDetails,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function closeModal() {
    setIsModalOpen(false);
    fetchEnvironmentDetails();
  }
  const { user } = useContext(AuthContext);

  const [fetching, setFetching] = useState(true);
  const [organizationsForUser, setOrganizationsForUser] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchOrganizationsForUser = useCallback(async () => {
    try {
      const response = await getOrganizationsForUser(user.userUUID);
      if (response.status === 200) {
        if (response.data.length > 0) {
          setOrganizationsForUser(response.data);
        } else {
          setOrganizationsForUser([]);
          setFetching(false);
        }
      } else {
        setOrganizationsForUser([]);
        setFetching(false);
      }
    } catch (e) {
      errorNotification(e.message);
      setFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchOrganizationsForUser();
  }, [fetchOrganizationsForUser]);

  async function assignEnvironmentToOrg(organization) {
    setLoading(true);
    const response = await assignEnvironmentToOrganization(
      environmentUuid,
      organization
    );
    if (response.status === 202) {
      successNotification("Environment has been assigned to organization");
      fetchEnvironmentDetails();
      setLoading(false);
    }
    if (response.status === 404) {
      errorNotification(response.data.message);
      setLoading(false);
    } else {
    }
  }

  function handleOnAssign(value) {
    assignEnvironmentToOrg(value);
  }

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonTextDefault>Manage</ButtonTextDefault>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Manage organizations</ModalTitle>
          <ModalText>
            Select an organization to assign to environment to or remove the
            environment from an organization. After adding the organization an
            use the environment to create new Spaces
          </ModalText>
          <ModalBody>
            <Container>
              <InputFieldWrapper>
                <InputFieldTitle>Select organization</InputFieldTitle>
                <select
                  value={selectedOption.organizationName}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  {organizationsForUser.map((link, index) => (
                    <option
                      key={link.organization.organizationName}
                      value={link.organization.organizationId}
                    >
                      {link.organization.organizationName}
                    </option>
                  ))}{" "}
                </select>
              </InputFieldWrapper>
              <button
                onClick={() => handleOnAssign(selectedOption)}
                type="submit"
              >
                {loading ? <LoaderCircleSmall /> : "Assign"}{" "}
              </button>
            </Container>
          </ModalBody>
          <div className="box-footer">
            <div onClick={() => closeModal()} className="close">
              <ButtonChip hasBackgroundColor>Done</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ModalManageAssigendOrganizations;

const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
