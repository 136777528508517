import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SubMenuBar from "../sub-menu/SubMenuBar";
import {
  errorNotification,
  infoNotification,
} from "../notifications/Notifications";
import { getOrganizationsForUser } from "../../api/services/OrganizationUserServices";
import {
  getSpaces,
  getSpacesForOrganization,
} from "../../api/services/SpaceProServices";
import { AuthContext } from "../../providers/AuthContextProvider";
import { SubMenuContainer } from "../sub-menu/SubMenuContainer";
import { SubMenuItem } from "../sub-menu/SubMenuItem";

import LoaderCircleBig from "../loader/LoaderCircleBig";
import SpacesAdd from "./SpacesAdd";
import TabLeft from "../../components/tabs/TabLeft";
import TabMiddle from "../tabs/TabMiddle";
import TabRight from "../tabs/TabRight";
import TabLeftSecondary from "../tabs/TabLeftSecondary";
import TabMiddleSecondary from "../tabs/TabMiddleSecondary";
import TabRightSecondary from "../tabs/TabRightSecondary";
import SpacesCards from "./SpacesCards";
import { ButtonChip } from "../button/ButtonChip";

//TODO refactor to smaller components
function SpacesSection() {
  const [fetching, setFetching] = useState(true);

  const [organizationsForUser, setOrganizationsForUser] = useState([]);
  const { user } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionObject, setSelectedOptionObject] = useState([]);
  const [spaceProForOrganization, setSpaceProForOrganization] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [createNewSpace, setCreateNewSpace] = useState(false);
  const [spacesPublic, setSpacesPublic] = useState([]);
  const [userOrganizationMember, setUserOrganizationMember] = useState(false);

  const [showPublic, setShowPublic] = useState(true);
  const [showPrivate, setShowPrivate] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);
  const [spaceType, setSpaceType] = useState("PUBLIC");

  const fetchData = useCallback(async () => {
    try {
      const response = await getOrganizationsForUser(user.userUUID);
      fetchSpacesProPublic();
      if (response.status === 200) {
        if (response.data.length > 0) {
          setOrganizationsForUser(response.data);
          fetchSpacesPro(response.data[0].organization.organizationId);
          setSelectedOption(response.data[0].organization.organizationName);
          setSelectedOptionObject(response.data[0]);
          setFetching(false);
          setUserOrganizationMember(true);
          if (response.data[0].organizationRole === "ORGANIZATION_ADMIN") {
            setAdmin(true);
          } else if (
            response.data[0].organizationRole === "ORGANIZATION_OWNER"
          ) {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        } else {
          setOrganizationsForUser([]);
          setFetching(false);
          setUserOrganizationMember(false);
        }
      } else {
        setUserOrganizationMember(false);
        setOrganizationsForUser([]);
        setFetching(false);
      }
    } catch (e) {
      errorNotification(e.message);
    }
  }, []);

  const fetchSpacesPro = useCallback(async (organizationId) => {
    try {
      const response = await getSpacesForOrganization(organizationId);
      if (response.data) {
        setSpaceProForOrganization(response.data);
      } else {
        setSpaceProForOrganization([]);
      }
    } catch (error) {
      errorNotification("Files could not be loaded");
    }
  }, []);

  const fetchSpacesProPublic = useCallback(async () => {
    try {
      const response = await getSpaces("PUBLIC");
      if (response.data) {
        setSpacesPublic(response.data);
        setFetching(false);
      } else {
        setSpacesPublic([]);
        setFetching(false);
      }
    } catch (error) {
      setFetching(false);

      errorNotification("Files could not be loaded");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleOnChangeSelected(value) {
    setFetching(true);
    setSelectedOption(value);

    const currentSelectionObject = organizationsForUser.find(
      (organization) => organization.organization.organizationName === value
    );
    fetchSpacesPro(currentSelectionObject.organization.organizationId);
    setSelectedOptionObject(currentSelectionObject);
    setFetching(false);
    console.log(selectedOptionObject.organization.organizationId);
    if (currentSelectionObject.organizationRole === "ORGANIZATION_ADMIN") {
      setAdmin(true);
    } else if (
      currentSelectionObject.organizationRole === "ORGANIZATION_OWNER"
    ) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }

  const renderSpaces = () => {
    if (showOrganization) {
      if (fetching) {
        return <LoaderCircleBig></LoaderCircleBig>;
      }
      return (
        <>
          <SpacesCards
            dataToRender={spaceProForOrganization}
            fetching={fetching}
          />
        </>
      );
    }
    if (showPublic) {
      if (fetching) {
        return <LoaderCircleBig></LoaderCircleBig>;
      }
      return (
        <>
          <SpacesCards dataToRender={spacesPublic} fetching={fetching} />
        </>
      );
    }
  };

  const renderNewSpace = () => {
    return (
      <>
        <SpacesAdd
          setCreateNewSpace={setCreateNewSpace}
          selectedType={spaceType}
          userOrganizationMember={userOrganizationMember}
          selectedOrganization={selectedOptionObject}
        />
      </>
    );
  };

  const handleOnClickPublic = () => {
    setShowPublic(true);
    setShowPrivate(false);
    setShowOrganization(false);
    // setSpaceType('PUBLIC');
    fetchSpacesProPublic();
  };

  const handleOnClickPrivate = () => {
    // setSpaceType('PUBLIC');
    infoNotification("Private spaces are not yet available");
  };

  const handleOnClickOrganization = () => {
    setShowPublic(false);
    setShowPrivate(false);
    setShowOrganization(true);
    setSpaceType("ORGANIZATION");
  };

  return (
    <>
      {createNewSpace ? (
        renderNewSpace()
      ) : (
        <>
          <>
            <SubMenuContainer>
              <SubMenuBar>
                <SubMenuItem>
                  <div onClick={handleOnClickPublic}>
                    {showPublic ? (
                      <TabLeft>Public</TabLeft>
                    ) : (
                      <TabLeftSecondary>Public</TabLeftSecondary>
                    )}
                  </div>
                  <div onClick={handleOnClickOrganization}>
                    {showOrganization ? (
                      <>
                        {userOrganizationMember ? (
                          <>
                            <TabMiddle>Organization</TabMiddle>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        {userOrganizationMember ? (
                          <>
                            {" "}
                            <TabMiddleSecondary>
                              Organization
                            </TabMiddleSecondary>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                  <div onClick={handleOnClickPrivate}>
                    {showPrivate ? (
                      <TabRight>Private</TabRight>
                    ) : (
                      <TabRightSecondary>Private</TabRightSecondary>
                    )}
                  </div>
                </SubMenuItem>
                {showOrganization ? (
                  <>
                    {userOrganizationMember ? (
                      <>
                        <>
                          {organizationsForUser.length > 1 && (
                            <SubMenuItem>
                              <select
                                value={selectedOption}
                                onChange={(e) =>
                                  handleOnChangeSelected(e.target.value)
                                }
                              >
                                {organizationsForUser.map((link, index) => (
                                  <option
                                    key={link.organization.organizationName}
                                    value={link.organization.organizationName}
                                  >
                                    {link.organization.organizationName}
                                  </option>
                                ))}
                              </select>
                            </SubMenuItem>
                          )}
                        </>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : null}
                {showOrganization ? (
                  <>
                    <SubMenuItem>
                      {admin ? (
                        // <div onClick={() => navigate("/spaces/new-space")}>
                        <div onClick={() => setCreateNewSpace(true)}>
                          <ButtonChip width={140} height={40}>
                            {/*<Plus />*/}
                            Add Space
                          </ButtonChip>
                        </div>
                      ) : (
                        <></>
                      )}
                    </SubMenuItem>
                  </>
                ) : (
                  <></>
                )}
              </SubMenuBar>
            </SubMenuContainer>
            <SpacesContainer>
              <SpaceOverwiew>
                <SpaceList>
                  <GridContainer>
                    <SpaceGrid>{renderSpaces()}</SpaceGrid>
                  </GridContainer>
                </SpaceList>
              </SpaceOverwiew>
            </SpacesContainer>
          </>
        </>
      )}
    </>
  );
}

export default SpacesSection;

const SpacesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SpaceOverwiew = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  @media (min-width: 1400px) {
    width: 60vw;
  }
`;

const SpaceList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const GridContainer = styled.div`
  height: 100%;
`;

const SpaceGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  row-gap: 40px;
  column-gap: 40px;
  width: 100%;
`;
