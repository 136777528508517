import client from "../Client";
import axios from "axios";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

const source = axios.CancelToken.source();

export const getEnvironmentsForCreatingSpace = async (assigneeType, uuid) => {
  try {
    const response = await client.get(apiUrl + `/api/v1/spaces/env/?environmentAssignee=${assigneeType}&uuid=${uuid}`);
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getCreatorDetails = async (userUuid) => {
  try {
    const response = await client.get(
      apiUrl + `/api/v1/environments/creators/${userUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getEnvironmentsForCreator = async (userUuid, isPublished) => {
  try {
    const response = await client.get(
      apiUrl + `/api/v1/environments/${userUuid}?isPublished=${isPublished}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const assignEnvironmentToOrganization = async ( environmentUuid, organizationUuid) => {
  try {
    const response = await client.post(
        apiUrl + `/api/v1/spaces/env/${environmentUuid}/organizations/${organizationUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const assignEnvironmentToUser = async ( environmentUuid, userEmail) => {
  try {
    const response = await client.post(
        apiUrl + `/api/v1/spaces/env/${environmentUuid}/users`,
    {
      userEmail: userEmail,
    }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getPublishedEnvironmentsForCreator = async (environmentUuid) => {
  try {
    const response = await client.get(
        apiUrl + `/api/v1/spaces/env/${environmentUuid}/creators`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getEnvironmentWithUuid = async (environmentUuid) => {
  try {
    const response = await client.get(
        apiUrl + `/api/v1/environments/single/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const publishEnvironmentForUse = async (environmentUuid) => {
  try {
    const response = await client.put(
      apiUrl + `/api/v1/environments/publish/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const submitEnvironmentForReview = async (environmentUuid) => {
  try {
    const response = await client.put(
      apiUrl + `/api/v1/environments/submissions/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const deleteNonPublishedEnvironment = async (environmentUuid) => {
  try {
    const response = await client.delete(
        apiUrl + `/api/v1/environments/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const createNewActiveCreator = async (
  creatorUuid,
  creatorName,
  creatorBio,
  creatorEmail
) => {
  try {
    const response = await client.post(
      apiUrl + "/api/v1/environments/creators/",
      {
        creatorUuid: creatorUuid,
        creatorName: creatorName,
        creatorBio: creatorBio,
        creatorEmail: creatorEmail,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const submitNewEnvironment = async (
  creatorUuid,
  name,
  shortSummary,
  longSummary,
    isPublic
) => {
  try {
    const response = await client.post(
      apiUrl + `/api/v1/environments/${creatorUuid}`,
      {
        name: name,
        shortSummary: shortSummary,
        longSummary: longSummary,
        public: isPublic,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
