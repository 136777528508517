import React, { useState } from "react";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import styled from "styled-components";
import { SubMenuContainer } from "../../components/sub-menu/SubMenuContainer";
import SubMenuBar from "../../components/sub-menu/SubMenuBar";
import { SubMenuItem } from "../../components/sub-menu/SubMenuItem";
import { ModalUploadFile } from "../../components/modal/ModalUploadFile";
import { FileList } from "../../components/file/FileList";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";

function ContentOverview() {
  const [file, setFileState] = useState(false);

  return (
    <>
      <LayoutDefaultPageClosed>
        <PageDetailsProvider
          title={"Content"}
          description={"Content overview"}
          meta={""}
        />

        <SubMenuContainer>
          <SubMenuBar>
            <SubMenuItem>
              <div>
                <ModalUploadFile setFileState={setFileState} />
              </div>
            </SubMenuItem>
          </SubMenuBar>
        </SubMenuContainer>
        <ContentContainer>
          <ContentWrapper>
            <FileList setFilestate={setFileState} newFileUploaded={file} />
          </ContentWrapper>
        </ContentContainer>
      </LayoutDefaultPageClosed>
    </>
  );
}

export default ContentOverview;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div``;
