import client from '../Client';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;


export const getEventDetails = async (eventUUID) => {
  try {
    const response = await client.get(
        `${apiUrl}/api/v1/events/${eventUUID}`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
