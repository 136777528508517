import React, { useCallback, useEffect, useState } from "react";
import { errorNotification } from "../../components/notifications/Notifications";
import { useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { getEnvironmentWithUuid } from "../../api/services/EnvironmentServices";
import ModalMobileOnly from "../../components/modal/ModalMobileOnly";
import { getWebGLBuildConfig } from "../../api/services/ConfigServices";
import WebGL from '../../feature/web-gl/WebGLViewForCreators';

function WebGlSection() {
  const envId = process.env.REACT_APP_SYSTEMS_ENV_ID;
  const apiBaseUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL + "/";
  const photonMode = 0;
  const { environmentUuid } = useParams();
  const [spacePro, setSpacePo] = useState({});
  const [room, setRoom] = useState({
    photonRoomId: "rzGPZ7eDBeE",
    agoraToken:
      "006ff90a02189ee44ac841dc640e712870fIACtodSvZrpQbvBWFHp0LNLyU+3IwlvhKXGqxw3+ZqPTjG8XK3UKvT3QIgAjFC3tk42xYgQAAQCzyLBiAgCzyLBiAwCzyLBiBACzyLBi",
    sessionUserId: "4e7f2668-f5d1-48a0-893a-e0cc4648ef9a-9YSRceUGCB",
    hostUserUuid: "1910ac16-46d5-424f-b818-e7fe6d2cc744-XMhqu2HkU9",
    portals: [],
    defaultSpaceRoles: ["GUEST"],
    spaceRolesUser: [],
    activeActor: false,
  });
  const [apiDone, setApiDon] = useState(false);
  const navigate = useNavigate();
  const [buildUrl, setBuildUrl] = useState("");
  const [streamingAssetsUrl, setStreamingAssetsUrl] = useState("");

  function createRandomPhotonRoomId() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 24; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function checkEnvironmentUlr(environment) {
    if (environment.metadataPreviewImage) {
      return environment.metadataPreviewImage.previewImageUrl;
    } else {
      return "/1920x1080.png";
    }
  }

  const fetchCreatorEnvironment = useCallback(async () => {
    try {
      const response = await getEnvironmentWithUuid(environmentUuid);
      if (response.status === 200) {
        const environmentImageUrl = checkEnvironmentUlr(response.data);
        const x = {
          created_at: "2022-06-13T11:52:32.991381Z",
          updatedAt: null,
          spaceUuid: createRandomPhotonRoomId(),
          spaceName: "-",
          description: "-",
          codeProtected: false,
          spaceType: "ORGANIZATION",
          environmentPro: {
            created_at: "2022-06-12T20:12:30.198058Z",
            updatedAt: null,
            environmentUuid: response.data.environmentUuid,
            name: "-",
            description: "-",
            imageUrl: environmentImageUrl,
            unitySceneName: null,
            assetBundleUrl: response.data.metadataAssetBundle.assetBundleUrl,
            active: false,
            publicEnvironment: false,
          },
          defaultSpaceRoles: [{ appRoleName: "GUEST" }],
          spaceUrl: null,
          roomIsOnline: false,
          photonRoomId: createRandomPhotonRoomId(),
          sessionSpaceId: createRandomPhotonRoomId(),
          photonRoomUserDtoList: null,
          active: true,
        };
        const y = {
          photonRoomId: createRandomPhotonRoomId(),
          agoraToken:
            "006ff90a02189ee44ac841dc640e712870fIACtodSvZrpQbvBWFHp0LNLyU+3IwlvhKXGqxw3+ZqPTjG8XK3UKvT3QIgAjFC3tk42xYgQAAQCzyLBiAgCzyLBiAwCzyLBiBACzyLBi",
          sessionUserId: createRandomPhotonRoomId(),
          hostUserUuid: createRandomPhotonRoomId(),
          portals: [],
          defaultSpaceRoles: ["GUEST"],
          spaceRolesUser: [],
          activeActor: false,
        };
        setSpacePo(x);
        setRoom(y);
        setApiDon(true);
      }
      if (response.status === 404) {
      } else {
        // errorNotification(response.data.message);
      }
    } catch (e) {
      errorNotification(e.message);
    }
  }, []);

  const fetchWebGLConfig = useCallback(async () => {
    try {
      const response = await getWebGLBuildConfig(envId);
      if (response.data) {
        setBuildUrl(response.data.build.buildBaseUrl);
        const url = response.data.build.buildBaseUrl.substring(
          0,
          response.data.build.buildBaseUrl.lastIndexOf("/")
        );
        const url2 = response.data.build.buildBaseUrl.substring(
          0,
          url.lastIndexOf("/")
        );
        setStreamingAssetsUrl(url2);
      } else {
        errorNotification("InputFieldError loading space");
        // navigate("/spaces");
      }
    } catch (error) {
      errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  }, []);

  useEffect(() => {
    fetchWebGLConfig().then(() => {
      fetchCreatorEnvironment();
    });
  }, []);

  return (
    <>
      <BrowserView>
        {apiDone === true && (
          <WebGL
            spacePro={spacePro}
            room={room}
            buildUrl={buildUrl}
            streamingAssetsUrl={streamingAssetsUrl}
            agoraEnabled={false}
            apiBaseUrl={apiBaseUrl}
            photonMode={photonMode}
          />
        )}
      </BrowserView>
      <MobileView>
        <ModalMobileOnly />
      </MobileView>
    </>
  );
}

export default WebGlSection;
