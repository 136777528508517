import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import MediaPlayerVideo from "../../feature/web-rtc/components/MediaPlayerVideo";
import styled from "styled-components";
import "./Call.css";
import { AuthContext } from "../../providers/AuthContextProvider";
import {
  getAllSpacesAsSysAdmin,
  getTokenForStreaming,
} from "../../api/services/SpaceProServices";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SubMenuContainer } from "../../components/sub-menu/SubMenuContainer";
import SubMenuBar from "../../components/sub-menu/SubMenuBar";
import { SubMenuItem } from "../../components/sub-menu/SubMenuItem";
import { ButtonChip } from "../../components/button/ButtonChip";
import { warningNotification } from "../../components/notifications/Notifications";

/* This page is a prototype to research on the Streaming functionality within the Ravel Platform.  */
function StreamingDashboard() {
  const { Paragraph } = Typography;
  const [appid, setAppid] = useState("e56c1f2539d34ad0b7055d025d50f900");
  const [remoteFeeds, setRemoteFeeds] = useState([]);
  const [localPlayerNames, setLocalPlayerNames] = useState("-");
  const [videoProfile, setVideoProfile] = useState("1080p_3");
  const [randomNumber] = useState(Math.floor(Math.random() * 100));
  const [channel, setChannel] = useState("streamTestLocal");
  const [userId, setUserId] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const { user } = useContext(AuthContext);
  const [spaces, setSpaces] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [fetchingSpaces, setFetchingSpaces] = useState(true);
  const [streamIsLive, setStreamIsLive] = useState(false);

  function getSpaces() {
    getAllSpacesAsSysAdmin()
      .then((response) => {
        setSpaces(response.data);
        setSelectedSpace(response.data[0].sessionSpaceId);
      })
      .finally(() => {
        setFetchingSpaces(false);
      });
  }

  useEffect(() => {
    getSpaces();
  }, []);

  function handleOnCreateSessionDetails() {
    getTokenForStreaming(
      `livestream-${selectedSpace}`,
      user.userUUID + "-" + randomNumber,
      "ROLE_PUBLISHER"
    )
      .then((res) => {
        setAppid(res.data.createdForAppId);
        setSessionToken(res.data.sessionToken);
        setExpiresAt(res.data.expiresAt);
        setUserId(res.data.createdForUserId);
        setChannel(res.data.createdForChannel);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // create Agora client
  var client = AgoraRTC.createClient({
    mode: "live",
    codec: "vp8",
    role: "host",
  });
  AgoraRTC.enableLogUpload();

  var localTracks = {
    screenVideoTrack: null,
    audioTrack: null,
    screenAudioTrack: null,
  };
  var remoteUsers = {};
  // Agora client options
  var options = {
    appid: appid,
    channel: "test",
    uid: randomNumber,
    token:
      "006b5ef7fb8a6004bbab48d15fe4e6b13fdIABnS3WaO15bfrI9zK2sFh6NeyX5Z6XVw8lDOg5T7beKtwx+f9gAAAAAEACOhaHHUnjiYgEAAQBSeOJi",
  };

  async function handleOnJoin() {
    await join();
  }

  async function join() {
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.setClientRole("host");
    let screenTrack;
    [options.uid, localTracks.audioTrack, screenTrack] = await Promise.all([
      client.join(appid, `livestream-${selectedSpace}`, sessionToken, userId),
      AgoraRTC.createMicrophoneAudioTrack(),
      AgoraRTC.createScreenVideoTrack(
        {
          encoderConfig: videoProfile,
          optimizationMode: "detail",
        },
        "auto"
      ),
    ]);

    if (screenTrack instanceof Array) {
      localTracks.screenVideoTrack = screenTrack[0];
      localTracks.screenAudioTrack = screenTrack[1];
    } else {
      localTracks.screenVideoTrack = screenTrack;
    }
    localTracks.screenVideoTrack.play("local-player");
    setLocalPlayerNames(options.uid);
    localTracks.screenVideoTrack.on("track-ended", () => {
      warningNotification(
        `Screen-share track ended, stop sharing screen ` +
          localTracks.screenVideoTrack.getTrackId()
      );
      localTracks.screenVideoTrack && localTracks.screenVideoTrack.close();
      localTracks.screenAudioTrack && localTracks.screenAudioTrack.close();
      localTracks.audioTrack && localTracks.audioTrack.close();
    });
    if (localTracks.screenAudioTrack == null) {
      await client.publish([
        localTracks.screenVideoTrack,
        localTracks.audioTrack,
      ]);
    } else {
      await client.publish([
        localTracks.screenVideoTrack,
        localTracks.audioTrack,
        localTracks.screenAudioTrack,
      ]);
    }
    setStreamIsLive(true);
    warningNotification(`You're broadcasting a stream`);
    console.log("publish success");
  }

  //
  // async function leave() {
  //   for (trackName in localTracks) {
  //     var track = localTracks[trackName];
  //     if(track) {
  //       track.stop();
  //       track.close();
  //       localTracks[trackName] = undefined;
  //     }
  //   }
  //
  //   // remove remote users and player views
  //   remoteUsers = {};
  //   // $("#remote-playerlist").html("");
  //
  //   // leave the channel
  //   await client.leave();
  //
  //   // $("#local-player-name").text("");
  //   // $("#join").attr("disabled", false);
  //   // $("#leave").attr("disabled", true);
  //   console.log("client leaves channel success");
  // }

  async function subscribe(user, mediaType) {
    const uid = user.uid;
    await client.subscribe(user, mediaType);
    if (mediaType === "video") {
      const player = `
      <div id="player-wrapper-${uid}">
        <p class="player-name">remoteUser(${uid})</p>
        <div id="player-${uid}" class="player"></div>
      </div>
    `;
      setRemoteFeeds([...remoteFeeds, player]);
      user.videoTrack.play(`player-${uid}`);
    }
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  }

  function handleUserPublished(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
  }

  function handleUserUnpublished(user, mediaType) {
    if (mediaType === "video") {
      const id = user.uid;
      delete remoteUsers[id];
      `#player-wrapper-${id}`.remove();
    }
  }

  const renderSpaces = () => {
    if (fetchingSpaces) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <label htmlFor="spaceToStream">Select space to stream to</label>
        <select
          name="spaceToStream"
          id="spaceToStream"
          onChange={handleOnSpaceChange}
        >
          {spaces.map((space) => (
            <option key={space.spaceUuid} value={space.sessionSpaceId}>
              {space.spaceName} - {space.sessionSpaceId}
            </option>
          ))}
        </select>
      </>
    );
  };

  const handleOnSpaceChange = (e) => {
    setSelectedSpace(e.target.value);
  };

  const handleOnVideoChange = (e) => {
    setVideoProfile(e.target.value);
  };

  return (
    <LayoutDefaultPageClosed>
      <SubMenuContainer>
        <SubMenuBar>
          <SubMenuItem></SubMenuItem>
        </SubMenuBar>
      </SubMenuContainer>
      <div>
        <DetailsContainer>
          <SessionDetailsContainer>
            <InfoContainer>
              {renderSpaces()}
              <label htmlFor="videoProfiles">Choose video profile</label>
              <select
                name="videoProfiles"
                id="videoProfiles"
                value={videoProfile}
                onChange={handleOnVideoChange}
              >
                <option value="720p_2">720p_2 - 30fps - 1710Kbps</option>
                <option value="1080p_3">1080p_3 - 30fps - 3150Kbps</option>
                <option value="1440p_1">1140p_1 - 30fps - 4850Kbps</option>
                <option value="4K_1">4k_1 - 30fps - 8910Kbps</option>
                <option value="4K_3">4K_3 - 60fps - 13500Kbps</option>
              </select>
              <ButtonChip
                onClick={handleOnCreateSessionDetails}
                width={140}
                height={40}
              >
                Create session details
              </ButtonChip>
              <ButtonChip onClick={handleOnJoin} width={140} height={40}>
                Publish stream
              </ButtonChip>
            </InfoContainer>
          </SessionDetailsContainer>
          <SessionDetailsContainer>
            <InfoContainer>
              <Paragraph>
                {" "}
                for userId:{" "}
                <Paragraph code copyable={true}>
                  {userId}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                using channel:{" "}
                <Paragraph code copyable={true}>
                  {channel}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                using appid:{" "}
                <Paragraph code copyable={true}>
                  {appid}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                using token:{" "}
                <Paragraph
                  code
                  style={{ maxWidth: "100%" }}
                  ellipsis
                  copyable={true}
                >
                  {sessionToken}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                expires at:{" "}
                <Paragraph style={{ maxWidth: "100%" }} code copyable={true}>
                  {expiresAt}
                </Paragraph>
              </Paragraph>
              <Paragraph>
                selected video-profile: <Paragraph>{videoProfile}</Paragraph>
              </Paragraph>
              <Paragraph>
                {" "}
                for userId:{" "}
                <Paragraph code copyable={true}>
                  {userId}
                </Paragraph>
              </Paragraph>
            </InfoContainer>
          </SessionDetailsContainer>
        </DetailsContainer>
        <ContentContainer>
          <div className="row video-group">
            <div className="col">
              {streamIsLive && (
                <>
                  <Paragraph style={{ color: "red" }}>
                    Status: LIVE - YOU'RE BROADCASTING A STREAM{" "}
                  </Paragraph>
                </>
              )}
              <div id="local-player" className="player"></div>
            </div>
            <div className="w-100"></div>
            <div className="col">
              <div id="remote-playerlist">
                <MediaPlayerVideo videoTrack={remoteFeeds.videoTrack} />
              </div>
            </div>
          </div>
        </ContentContainer>
      </div>
    </LayoutDefaultPageClosed>
  );
}

export default StreamingDashboard;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const SessionDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 40%;
  height: 250px;
  flex-wrap: wrap;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  flex-wrap: wrap;
`;
