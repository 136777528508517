import { useCallback, useContext, useEffect, useState } from "react";
import {
  getSessionDetails,
  getSpacePro,
} from "../../api/services/SpaceProServices";
import { errorNotification } from "../../components/notifications/Notifications";
import { useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { getWebGLBuildConfig } from "../../api/services/ConfigServices";
import WebGL from "../../feature/web-gl/WebGL";
import { validateGateAccess } from "../../api/services/GateServices";
import { NoAccessToSpace } from "./NoAccessToSpace";
import styled from "styled-components";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";
import ModalMobileOnly from "../../components/modal/ModalMobileOnly";
import { AuthContext } from "../../providers/AuthContextProvider";
import socketio from "socket.io-client";
import DevCycle from "../../utils/DevCycle";

function WebGLViewer() {
  const [title, setTitle] = useState("");

  const envId = process.env.REACT_APP_SYSTEMS_ENV_ID;
  const apiBaseUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL + "/";
  const photonMode = process.env.REACT_APP_SYSTEMS_PHOTON_MODE;
  const { spaceId } = useParams();
  const [spacePro, setSpacePo] = useState({});
  const [room, setRoom] = useState({});
  const [gateDetails, setGateDetails] = useState({});
  const { user } = useContext(AuthContext);
  const [apiDone, setApiDon] = useState(false);
  const [gateIsOpen, setGateIsOpen] = useState(true);
  const navigate = useNavigate();
  const [buildUrl, setBuildUrl] = useState("");
  const [streamingAssetsUrl, setStreamingAssetsUrl] = useState("");

  const fetchSpacePro = useCallback(async () => {
    try {
      const response = await getSpacePro(spaceId);
      if (response.data) {
        setSpacePo(response.data);
        setTitle(response.data.spaceName);

        await validateGateAccess(response.data.spaceUuid).then((response) => {
          if (response.status === 404) {
            setGateIsOpen(false);
            setGateDetails(response.data);
          }
          if (response.status === 200) {
            if (response.data) {
              if (!response.data.hasAccess) {
                setGateIsOpen(false);
                setGateDetails(response.data);
              }
            }
          }
        });
      } else {
        errorNotification("InputFieldError loading space");
        navigate("/spaces");
      }
    } catch (error) {
      errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  }, []);

  const fetchRoom = useCallback(async () => {
    try {
      const response = await getSessionDetails(user.userUUID, spaceId);
      if (response.data) {
        setRoom(response.data);
      } else {
        errorNotification("InputFieldError loading space");
        navigate("/spaces");
      }
    } catch (error) {
      errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  }, []);

  const fetchWebGLConfig = useCallback(async () => {
    try {
      const response = await getWebGLBuildConfig(envId);
      if (response.data) {
        setBuildUrl(response.data.build.buildBaseUrl);
        const url = response.data.build.buildBaseUrl.substring(
          0,
          response.data.build.buildBaseUrl.lastIndexOf("/")
        );
        const url2 = response.data.build.buildBaseUrl.substring(
          0,
          url.lastIndexOf("/")
        );
        setStreamingAssetsUrl(url2);
      } else {
        errorNotification("InputFieldError loading space");
        // navigate("/spaces");
      }
    } catch (error) {
      errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  }, []);

  function returnToSpaces() {
    {
      navigate("/spaces");
    }
  }

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    fetchSpacePro().then(() =>
      fetchRoom().then(() => fetchWebGLConfig().finally(() => setApiDon(true)))
    );
    return () => {};
  }, []);

  useEffect(() => {
    const newSocket = socketio.connect(
      // `http://localhost:3000`
      `https://io.ravel.systems`
      // `https://ruben.ravel.systems.eu.ngrok.io`
    );
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  return (
    <>
      <DevCycle>
        <PageDetailsProvider
          title={title}
          description={spacePro.description}
          meta={"hellow"}
        />
        <BrowserView>
          {!gateIsOpen && (
            <LoadingOverlay img={spacePro.environmentPro.imageUrl}>
              <NoAccessToSpace
                gateDetails={gateDetails}
                isModalOpen={gateIsOpen}
                returnToSpaces={returnToSpaces}
              />
            </LoadingOverlay>
          )}

          {apiDone && gateIsOpen && (
            <>
              {socket ? (
                <>
                  <WebGL
                    spacePro={spacePro}
                    room={room}
                    buildUrl={buildUrl}
                    streamingAssetsUrl={streamingAssetsUrl}
                    agoraEnabled={true}
                    apiBaseUrl={apiBaseUrl}
                    photonMode={photonMode}
                    socketio={socket}
                  />
                </>
              ) : (
                <>NOT CONNECTED</>
              )}
            </>
          )}
        </BrowserView>
        <MobileView>
          <ModalMobileOnly />
        </MobileView>
      </DevCycle>
    </>
  );
}

export default WebGLViewer;

const LoadingOverlay = styled.div`
  background: linear-gradient(
      111.43deg,
      rgba(122, 118, 118, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(${(props) => props.img});
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
