import { DefaultPageLayoutOpen } from "../../components/layout/LayoutDefaultPageOpen";
import { CardWrapper } from "../../components/cards/CardWrapper";
import { CardHeaderWrapper } from "../../components/cards/CardHeaderWrapper";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import React, { useState } from "react";
import FormSubmitNewEnvironment from "../../components/form/FormSubmitNewEnvironment";
import { CreatorsUploadPreviewImage } from "../../components/creators/CreatorsUploadPreviewImage";
import { CreatorsUploadAssetBundle } from "../../components/creators/CreatorsUploadAssetBundle";
import Card from "../../components/cards/Card";
import styled from "styled-components";
import { ButtonChip } from "../../components/button/ButtonChip";
import { useNavigate } from "react-router-dom";

export const CreatorsNewEnvironment = () => {
  const navigate = useNavigate();
  const [provideDetails, setProvideDetails] = useState(true);
  const [uploadPreviewImage, setUploadPreviewImage] = useState(false);
  const [uploadAssetBundle, setUploadAssetBundle] = useState(false);
  const [createdEnvironment, setCreatedEnvironment] = useState({});

  function handleOnEnvironmentCreated(environment) {
    setCreatedEnvironment(environment);
    setProvideDetails(false);
    setUploadPreviewImage(true);
  }

  function handleOnPreviewImageUploaded() {}
  function handleOnAssetBundleUploaded() {
    setUploadAssetBundle(false);
  }

  return (
    <>
      <DefaultPageLayoutOpen>
        <Card>
          <CardWrapper>
            {provideDetails === true && (
              <>
                <CardHeaderWrapper>
                  <ModalTitle>Create new environment</ModalTitle>
                  <ModalText>
                    Provide the details below to create a new environment. Later
                    on you can upload your content and preview image
                  </ModalText>
                  <div className="box-body">
                    <FormSubmitNewEnvironment
                      handleOnEnvironmentCreated={handleOnEnvironmentCreated}
                    />
                  </div>
                </CardHeaderWrapper>
              </>
            )}
            {uploadPreviewImage === true && (
              <>
                <CardHeaderWrapper>
                  <ModalTitle>Upload assets</ModalTitle>
                  <ModalText>
                    You can now upload the assets for: {createdEnvironment.name}
                  </ModalText>
                </CardHeaderWrapper>
                <div className="box-body">
                  <UploadWrapper>
                    <CreatorsUploadPreviewImage
                      environmentUuid={createdEnvironment.environmentUuid}
                      setFileState={handleOnPreviewImageUploaded}
                    />

                    <CreatorsUploadAssetBundle
                      environmentUuid={createdEnvironment.environmentUuid}
                      setFileState={handleOnAssetBundleUploaded}
                    />
                  </UploadWrapper>
                </div>
                <div onClick={() => navigate("/creators")}>
                  <ButtonChip
                    disabled={uploadAssetBundle}
                    hasBackgroundColor
                    width={140}
                    height={40}
                  >
                    Done
                  </ButtonChip>
                </div>
              </>
            )}
          </CardWrapper>
        </Card>
      </DefaultPageLayoutOpen>
    </>
  );
};

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
