import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import ProfileSection from "../../components/profile/ProfileSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";

function ProfileOverview() {
  return (
    <>
      <LayoutDefaultPageClosed>
        <PageDetailsProvider
          title={"Profile"}
          description={"Spaces overview"}
          meta={""}
        />

        <ProfileSection />
      </LayoutDefaultPageClosed>
    </>
  );
}

export default ProfileOverview;
