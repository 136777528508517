import {
  LayoutDefaultPageClosed,
} from '../../components/layout/LayoutDefaultPageClosed';
import {PageDetailsProvider} from '../../providers/PageDetailsProvider';
import {SpacesEventSection} from '../../components/spaces/SpacesEventSection';

export const EventSpacesOverview = () => {
  return (
    <>
      <LayoutDefaultPageClosed>
        <PageDetailsProvider
          title={"Event"}
          description={"Event overview"}
          meta={""}
        />
        <SpacesEventSection />
      </LayoutDefaultPageClosed>
    </>
  );
};
