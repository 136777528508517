import { useForm } from "react-hook-form";
import { useState } from "react";
import styles from "../../components/form/DefaultForm.modules.css";
import { InputFieldWrapper } from "../../components/form/InputFieldWrapper";
import { InputFieldTitle } from "../../components/form/InputFieldTitle";
import { InputFieldError } from "../../components/form/InputFieldError";
import LoaderCircleSmall from "../../components/loader/LoaderCircleSmall";
import { requestResetPassword } from "../../api/services/AccountServices";
import { errorNotification } from "../../components/notifications/Notifications";
import * as Sentry from "@sentry/react";

function FormForgotPassword({ emailSend }) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await requestResetPassword(data);
      if (response.status === 200) {
        setLoading(false);
        emailSend();
      }
      if (response.status === 404) {
        errorNotification("Please try again");
        setLoading(false);
        Sentry.setUser({ email: data.email });
        Sentry.captureException("Forgot password error");
      }
      setLoading(false);
    } catch (e) {
      Sentry.setUser({ email: data.email });
      Sentry.captureException("Forgot password error");
      errorNotification(e.data.message);
      setLoading(false);
      reset();
    }
  };

  return (
    <>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Email</InputFieldTitle>
            <input {...register("email", { required: true })} type="email" />
            {errors.email && (
              <InputFieldError>Email is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Submit"}{" "}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormForgotPassword;
