import { getUserFiles } from "../../api/services/UserFilesServices";
import React, { useCallback, useEffect, useState } from "react";
import Table from "./Table";
import Card from "../cards/Card";
import styled from "styled-components";
import { requestDownloadUrl } from "../../api/services/UserFilesServices";
import { ButtonIcon } from "../button/ButtonIcon";
import { errorNotification } from "../notifications/Notifications";
import { ModalDeleteFile } from "../modal/ModalDeleteFile";
import LoaderCirclePrimary from "../loader/LoaderCircleBig";

const onClickGetFile = async (id) => {
  try {
    const { data } = await requestDownloadUrl(id);
    const file_path = await data.preSignedUrl;
    const a = document.createElement("A");

    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error(error);
    errorNotification("InputFieldError retrieving file");
  }
};

export const FileList = ({ setFilestate, newFileUploaded }) => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [fetching, setFetching] = useState(true);

  const columns = React.useMemo(
    () => [
      {
        Header: "file name",
        accessor: "fileName",
      },
      {
        Header: "file size",
        accessor: "fileSize",
        Cell: ({ cell: { value } }) => {
          const mb = Math.floor(value / 1000000);
          return (
            <>{mb > 0.0 ? `${mb} mb${mb > 1.0 ? " " : " "} ` : "1 mb <"}</>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <ButtonIcon
                hasBackground
                icon="trash"
                width={32}
                height={32}
                onClick={() => {
                  setItemToDelete(value);
                  setIsModalOpen(true);
                }}
              />

              <ButtonIcon
                hasBackground
                width={32}
                height={32}
                icon="download"
                onClick={() => onClickGetFile(value)}
              />
            </>
          );
        },
      },
    ],
    []
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await getUserFiles();

      if (response.status === 200) {
        setData(response.data);
        setFetching(false);
      } else {
        setData([]);
      }
    } catch (error) {
      errorNotification("Files could not be loaded");
    }
  }, []);

  const resetStates = useCallback(() => {
    setFilestate(false);
  }, [setFilestate]);

  useEffect(() => {
    fetchData().then((r) => setFetching(false));
  }, [fetchData]);

  useEffect(() => {
    if (!data || newFileUploaded) {
      resetStates();
      fetchData();
    }
  }, [data, newFileUploaded, resetStates, fetchData]);

  return (
    <>
      {fetching ? (
        <LoaderCirclePrimary />
      ) : !data?.length ? (
        <p>
          You can upload files by clicking on the upload button in the top right
          corner.
        </p>
      ) : (
        <>
          <Card>
            <ListWrapper>
              {fetching && <LoaderCirclePrimary />}
              <Table columns={columns} data={data} />
            </ListWrapper>
          </Card>
        </>
      )}
      {isModalOpen && (
        <ModalDeleteFile
          setIsModalOpen={setIsModalOpen}
          id={itemToDelete}
          fetchData={fetchData}
        />
      )}
    </>
  );
};

const ListWrapper = styled.div`
  //max-height: 100%;
  display: flex;
  justify-content: center;
  min-width: 300px;
  max-height: 500px;
  overflow-y: scroll;
  @media only screen and (max-width: 500px) {
    max-height: 300px;
  }

  .menu-icon {
    margin-left: 8px;
    height: 24px;
    display: inline-block;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  ////gap: 10px;
  //cursor: pointer;
  //max-width: 120px;
`;

const Switch = styled.div`
  position: relative;
  width: 56px;
  height: 28px;
  background: #e0e5f2;
  border-radius: 60px;

  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: #ffffff;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: relative;
  max-width: 1px;

  &:checked + ${Switch} {
    background: #4318ff;

    &:before {
      transform: translate(26px, -50%);
    }
  }
`;
