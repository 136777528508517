import styled from "styled-components";

export const SubMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  //margin: 20px;
`;
