import { CreatorsConfirmDeleteEnvironment } from "./CreatorsConfirmDeleteEnvironment";
import ModalUpdatePreviewImage from "../modal/ModalUpdatePreviewImage";
import ModalUpdateAssetBundle from "../modal/ModalUpdateAssetBundle";
import React from "react";

export const CreatorsEnvironmentActions = ({ environment }) => {
  return (
    <>
      {environment.published ? (
        <>
          <>Environment is published, cannot be updated</>
        </>
      ) : (
        <>
          {environment.submissionInProgress ? (
            <>
              {environment.qaApproved ? (
                <>
                  <CreatorsConfirmDeleteEnvironment
                    environmentUuid={environment.environmentUuid}
                  />
                </>
              ) : (
                <>
                  <CreatorsConfirmDeleteEnvironment
                    environmentUuid={environment.environmentUuid}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {environment.qaReviewed ? (
                <>
                  <CreatorsConfirmDeleteEnvironment
                    environmentUuid={environment.environmentUuid}
                  />
                </>
              ) : (
                <>
                  {environment.qaApproved ? (
                    <>
                      <CreatorsConfirmDeleteEnvironment
                        environmentUuid={environment.environmentUuid}
                      />
                    </>
                  ) : (
                    <>
                      <ModalUpdatePreviewImage
                        environmentUuid={environment.environmentUuid}
                      />
                      <ModalUpdateAssetBundle
                        environmentUuid={environment.environmentUuid}
                      />
                      <CreatorsConfirmDeleteEnvironment
                        environmentUuid={environment.environmentUuid}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
