import { CardWrapper } from "../cards/CardWrapper";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { SubMenuContainer } from "../sub-menu/SubMenuContainer";
import SubMenuBar from "../sub-menu/SubMenuBar";
import { SubMenuItem } from "../sub-menu/SubMenuItem";
import { CardContentWrapper } from "../cards/CardContentWrapper";
import { ButtonChip } from "../button/ButtonChip";
import styles from "../../components/form/DefaultForm.modules.css";
import { InputFieldWrapper } from "../form/InputFieldWrapper";
import { InputFieldTitle } from "../form/InputFieldTitle";
import { InputFieldError } from "../form/InputFieldError";
import { createOrganizationSpace } from "../../api/services/SpaceProServices";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../providers/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import { getEnvironmentsForCreatingSpace } from "../../api/services/EnvironmentServices";
import TabLeft from "../tabs/TabLeft";
import TabLeftSecondary from "../tabs/TabLeftSecondary";
import TabMiddle from "../tabs/TabMiddle";
import TabMiddleSecondary from "../tabs/TabMiddleSecondary";
import TabRight from "../tabs/TabRight";
import TabRightSecondary from "../tabs/TabRightSecondary";
import { Heading2 } from "../Headings";
import { Paragraph } from "../Paragraphs";

function SpacesAdd({
  setCreateNewSpace,
  selectedType,
  userOrganizationMember,
  selectedOrganization,
}) {
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function handleSubmit2(selectedElement) {
    setValue("environmentUuid", selectedElement);
    handleSubmit(onSubmit)();
  }

  const [loading, setLoading] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showPublic, setShowPublic] = useState(true);
  const [showPrivate, setShowPrivate] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(selectedOrganization.organization.organizationName);
    try {
      const response = await createOrganizationSpace(
        data.spaceName,
        data.description,
        false,
        selectedType,
        data.environmentUuid,
        data.defaultSpaceRole,
        user.userUUID,
        selectedOrganization.organization.organizationName
      );
      setLoading(false);
      const spaceId =
        response.headers.location.split("/")[
          response.headers.location.split("/").length - 1
        ];
      successNotification("Space created successfully");
      navigate(`/spaces/${spaceId}`);
    } catch (e) {
      console.error(e);
      errorNotification(e.data.message);
      setLoading(false);
      reset();
    }
  };

  async function getEnvironments(environmentType, forOrgOrUserUuid) {
    try {
      const response = await getEnvironmentsForCreatingSpace(
        environmentType,
        forOrgOrUserUuid
      );
      if (response.status === 200) {
        if (response.data.length > 0) {
          setEnvironments(response.data);
        } else {
          setEnvironments([]);
        }
      } else {
        errorNotification("InputFieldError getting environments");
        setEnvironments([]);
      }
    } catch (e) {
      console.error(e);
      errorNotification("InputFieldError getting environments");
    }
  }

  useEffect(() => {
    getEnvironments("PUBLIC", selectedOrganization.organization.organizationId);
  }, []);

  const handleOnClickPublic = () => {
    setShowPublic(true);
    setShowPrivate(false);
    setShowOrganization(false);
    getEnvironments("PUBLIC", selectedOrganization.organization.organizationId);
  };

  const handleOnClickPrivate = () => {
    setShowPublic(false);
    setShowPrivate(true);
    setShowOrganization(false);
    getEnvironments("USER", user.userUUID);
  };

  const handleOnClickOrganization = () => {
    setShowPublic(false);
    setShowPrivate(false);
    setShowOrganization(true);
    getEnvironments(
      "ORGANIZATION",
      selectedOrganization.organization.organizationId
    );
  };

  return (
    <>
      <SubMenuContainer>
        <SubMenuBar>
          <SubMenuItem onClick={() => setCreateNewSpace(false)}>
            <ButtonChip width={140} height={40}>
              Go back
            </ButtonChip>
          </SubMenuItem>
          <SubMenuItem> </SubMenuItem>
          <SubMenuItem> </SubMenuItem>
        </SubMenuBar>
      </SubMenuContainer>
      <DefaultPageClosedContentWrapper>
        <DefaultPageClosedContentContainer>
          <CardContentWrapper>
            <div className={styles.formContainer}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormContainer>
                  <InformationWrapper>
                    <Heading2>Details</Heading2>
                    <Paragraph>Provide the details for the Space</Paragraph>
                  </InformationWrapper>
                  <TextContainer>
                    <InputFieldWrapper>
                      <InputFieldTitle>Space name</InputFieldTitle>
                      <input
                        {...register("spaceName", { required: true })}
                        type="text"
                      />
                      {errors.spaceName && (
                        <InputFieldError>Name is required</InputFieldError>
                      )}
                    </InputFieldWrapper>

                    <InputFieldWrapper>
                      <InputFieldTitle>Description</InputFieldTitle>
                      <textarea
                        {...register("description", { required: true })}
                        type="text"
                      />
                      {errors.description && (
                        <InputFieldError>
                          Description is required
                        </InputFieldError>
                      )}
                    </InputFieldWrapper>

                    <InputFieldWrapper>
                      <InputFieldTitle>
                        Default role for all users
                      </InputFieldTitle>
                      <select {...register("defaultSpaceRole")}>
                        <option value="GUEST">Guest</option>
                        <option value="USER">User</option>
                        <option value="PRESENTER">Presenter</option>
                        <option value="MODERATOR">Moderator</option>
                      </select>
                    </InputFieldWrapper>
                  </TextContainer>
                  <InputFieldWrapper>
                    <InputFieldTitle
                      {...register("environmentUuid", { required: true })}
                      type="text"
                    />
                    {errors.environmentUuid && (
                      <InputFieldError>Environment is required</InputFieldError>
                    )}
                  </InputFieldWrapper>

                  <InformationWrapper>
                    <Heading2>Environments</Heading2>
                    <Paragraph>Select an environment for your space</Paragraph>
                  </InformationWrapper>

                  <SubMenuBar>
                    <SubMenuItem>
                      <div onClick={handleOnClickPublic}>
                        {showPublic ? (
                          <TabLeft>Public</TabLeft>
                        ) : (
                          <TabLeftSecondary>Public</TabLeftSecondary>
                        )}
                      </div>
                      <div onClick={handleOnClickOrganization}>
                        {showOrganization ? (
                          <>
                            {userOrganizationMember ? (
                              <TabMiddle>Organization</TabMiddle>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {userOrganizationMember ? (
                              <TabMiddleSecondary>
                                Organization
                              </TabMiddleSecondary>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                      <div onClick={handleOnClickPrivate}>
                        {showPrivate ? (
                          <TabRight>Private</TabRight>
                        ) : (
                          <TabRightSecondary>Private</TabRightSecondary>
                        )}
                      </div>
                    </SubMenuItem>
                  </SubMenuBar>

                  <EnvironmentsContainer>
                    <EnvironmentsList>
                      <EnvGrid>
                        {environments.map((link, index) => (
                          <GridItemContainer
                            onClick={() => handleSubmit2(link.environmentUuid)}
                            key={link.environmentUuid}
                          >
                            <CardWrapper>
                              <EnvImageWrapper>
                                <GridItemContent>
                                  <GridItemImageMask
                                    src={link.imageUrl}
                                    alt={link.name}
                                  ></GridItemImageMask>
                                  <GridItemTextContainer>
                                    <GridItemOnline>{link.name}</GridItemOnline>
                                  </GridItemTextContainer>
                                </GridItemContent>
                              </EnvImageWrapper>
                            </CardWrapper>
                          </GridItemContainer>
                        ))}
                      </EnvGrid>
                    </EnvironmentsList>
                  </EnvironmentsContainer>
                </FormContainer>
              </form>
            </div>
          </CardContentWrapper>
        </DefaultPageClosedContentContainer>
      </DefaultPageClosedContentWrapper>
    </>
  );
}

export default SpacesAdd;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

const DefaultPageClosedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DefaultPageClosedContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: nowrap;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: stretch;
  flex-wrap: wrap;
  width: 620px;
`;

const EnvGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EnvironmentsContainer = styled.div`
  height: 500px;
  display: flex;
  justify-content: center;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 70%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const GridItemOnline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 8px;
`;

const EnvironmentsList = styled.div`
  height: 100%;
`;

const EnvImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 150px;
  align-items: flex-start;
`;

const GridItemContainer = styled.div`
  height: 100%;
  position: relative;
  width: 290px;
`;

const GridItemContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const GridItemImageMask = styled.img`
  height: 100%;
  width: 100%;
  bottom: 0;
  border-radius: 30px;
  transition: 0.5s all;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
    transition: 0.5s;
    box-shadow: 0 16px 48px 0 rgb(0 0 0 / 24%);
  }
`;

const GridItemTextContainer = styled.div`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  padding: 0 24px 20px 28px;
  text-shadow: 0 0.25rem 0.375rem rgb(0 0 0 / 30%);
`;
