import React, { Component } from "react";
import Modal from "../../components/modal/Modal";
import { LogOutProvider } from "../../providers/LogOutProvider";
import { ButtonChip } from "../../components/button/ButtonChip";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import styled from 'styled-components';
import {ButtonTextStyles} from '../../styles/ButtonTextStyles';

//TODO refactor this to new component
class LogOut extends Component {
  state = {
    isModalOpen: false,
  };

  toggleState = (e) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  render() {
    return (
      <div>
        <span style={{ display: "flex" }} onClick={this.toggleState}>
          <ButtonText> Log out</ButtonText>
        </span>
        {this.state.isModalOpen && (
          <Modal
            id="modal"
            isOpen={this.state.isModalOpen}
            onClose={this.toggleState}
          >
            <div className="box-header">
              <ModalTitle>Log out</ModalTitle>
              <ModalText>Are you sure to log out?</ModalText>
            </div>
            <div className="box-footer">
              <div onClick={this.toggleState} className="close">
                <ButtonChip width={110} height={32}>
                  Cancel
                </ButtonChip>
              </div>
              <LogOutProvider>
                <ButtonChip hasBackgroundColor>Yes</ButtonChip>
              </LogOutProvider>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default LogOut;

const ButtonText = styled.button`
  ${ButtonTextStyles}
  min-width: 110px;
  min-height: 32px;
  font-size: 14px;
  line-height: 24px;
  text-align: start;
  justify-content: start;
`;
