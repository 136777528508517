import React, { useState } from "react";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { ModalTitle } from "./ModalTitle";
import { ModalText } from "./ModalText";
import { ButtonText } from "../button/ButtonText";
import { removeUserFromOrg } from "../../api/services/OrganizationUserServices";
import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";

function RemoveUserFromOrganization({ userUuid, organizationName, refresh }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const removeUser = async () => {
    try {
      const response = await removeUserFromOrg(organizationName, userUuid);
      if (response.status === 204) {
        closeModal();
        successNotification("User removed from organization");
        refresh();
      }
      if (response.status === 409) {
        closeModal();
        errorNotification("User is not part of organization");
      }
      if (response.status === 400) {
        closeModal();
        errorNotification("Not allowed to remove user from organization");
      }
      if (response.status === 404) {
        closeModal();
        errorNotification("User not found");
      }
    } catch (error) {}
  };

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonText>Remove user</ButtonText>
      </div>
      {isModalOpen && (
        <Modal
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalTitle>Remove user</ModalTitle>
          <ModalText>
            Are you sure to remove user from the organization?
          </ModalText>
          <div className="box-footer">
            <div onClick={() => setIsModalOpen(false)} className="close">
              <ButtonChip width={110} height={32}>
                Cancel
              </ButtonChip>
            </div>
            <div onClick={() => removeUser()} className="close">
              <ButtonChip hasBackgroundColor>Remove user</ButtonChip>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default RemoveUserFromOrganization;
