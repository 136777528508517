import { OnlineUserList } from "./OnlineUserList";
import React, { useContext, useEffect, useState } from "react";
import socketio from "socket.io-client";
import { AuthContext } from "../providers/AuthContextProvider";
import { LayoutDefaultPageClosed } from "../components/layout/LayoutDefaultPageClosed";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { ChatComponent } from "../feature/webSocket/ChatComponent";

export const SocketAdminDashboard = () => {
  const [socket, setSocket] = useState(null);
  const { user } = useContext(AuthContext);
  const { room } = useParams();

  useEffect(() => {
    const newSocket = socketio.connect(
      // `http://localhost:3000`
      `https://io.ravel.systems`
      // `https://ruben.ravel.systems.eu.ngrok.io`
    );
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  return (
    <>
      <LayoutDefaultPageClosed>
        {socket ? (
          <>
            <SectionWrapper>
              <OnlineUserList socket={socket} />
            </SectionWrapper>
            <SectionWrapperChat>
              <ChatComponent socket={socket} user={user} room={room} />
            </SectionWrapperChat>
          </>
        ) : (
          <>not connected...</>
        )}
      </LayoutDefaultPageClosed>
    </>
  );
};

const SectionWrapper = styled.div`
  display: flex;
  //max-width: 600px;
  //min-width: 600px;
`;

const SectionWrapperChat = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 200px;
  position: absolute;
  bottom: 90px;
  right: 150px;
`;
