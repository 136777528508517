import React, {Fragment,  useEffect, useState} from "react";
import {Unity, useUnityContext} from "react-unity-webgl";
import {errorNotification} from "../../components/notifications/Notifications";
import {getUser} from "../../helpers/CookieHelper";
import styled from "styled-components";
import ModalLeaveSpace from "../../components/modal/ModalLeaveSpace";
import Call from "../web-rtc/Call";
import LoaderCirclePrimary from "../../components/loader/LoaderCircleBig";
import {getSpaceProWithUuid} from "../../api/services/SpaceProServices";
import {useNavigate} from "react-router-dom";
import {ButtonRound} from "../../components/button/ButtonRound";
import {ModalUploadFileWebGL} from "../../components/modal/ModalUploadFileWebGL";
import {ReactComponent as Plus} from "../../assets/icons/plus.svg";

function WebGL(props: {
  spacePro: any;
  room: any;
  buildUrl: string;
  streamingAssetsUrl: string;
  agoraEnabled : boolean;
  apiBaseUrl: string;
  photonMode : number;
}) {
  const {
    spacePro,
    room,
    buildUrl,
    streamingAssetsUrl,
    agoraEnabled,
    apiBaseUrl,
    photonMode
  } = props;
  const {
    unityProvider,
    loadingProgression,
    isLoaded,
    unload,
    addEventListener, removeEventListener, sendMessage
  } = useUnityContext({
    loaderUrl: buildUrl + ".loader.js",
    dataUrl: buildUrl + ".data",
    frameworkUrl: buildUrl + ".framework.js",
    codeUrl: buildUrl + ".wasm",
    streamingAssetsUrl: streamingAssetsUrl + "/StreamingAssets",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    }
  });

  const navigate = useNavigate();

  const currentUser = getUser();
  const [isVoiceMounted, setIsVoiceMounted] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState("Downloading assets...");
  const delay = (ms: number | undefined) => new Promise((res) => setTimeout(res, ms));

  const [isUnityMounted, setIsUnityMounted] = useState<boolean>(true);
  const [isUnityRavelLoaded, setIsUnityRavelLoaded] = useState<boolean>(false);
  const [progression, setProgression] = useState<number>(0);
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState<boolean>(false);
  const [fileState, setFileState] = useState<boolean>(false);


  useEffect(() => {
    addEventListener("ServerPing", handleServerPing); //receives from unity when it received a ping and sends a ping back
    addEventListener("OnSpaceLoaded", handleSpaceLoaded); //receives from unity when the space is loaded
    addEventListener("OnEnterPortal", handleOnPortalEnter); //receives from unity when the player enters a portal
    addEventListener("OnLoadingActionChanged", handleOnLoadingActionChanged); //Event when loading starts and text changes
    addEventListener("OnLoadingFinished", handleOnLoadingFinished); //Loading screen can be turned off when this event triggers
    addEventListener("OnDisconnect", handleOnDisconnect); //receives from unity when the player is disconnected from the server
    addEventListener("UploadFile", handleOnUploadFile); //receives from unity if the player uploads a file

    return () => {
      removeEventListener("ServerPing", handleServerPing); //receives from unity when it received a ping and sends a ping back
      removeEventListener("OnSpaceLoaded", handleSpaceLoaded); //receives from unity when the space is loaded
      removeEventListener("OnEnterPortal", handleOnPortalEnter); //receives from unity when the player enters a portal
      removeEventListener("OnLoadingActionChanged", handleOnLoadingActionChanged); //Event when loading starts and text changes
      removeEventListener("OnLoadingFinished", handleOnLoadingFinished); //Loading screen can be turned off when this event triggers
      removeEventListener("OnDisconnect", handleOnDisconnect);
      removeEventListener("UploadFile", handleOnUploadFile)//receives from unity when the player is disconnected from the server
    };
  }, [
    addEventListener,
    removeEventListener,
    handleServerPing,
    handleSpaceLoaded,
    handleOnPortalEnter,
    handleOnLoadingActionChanged,
    handleOnLoadingFinished,
    handleOnDisconnect,
    handleOnUploadFile
  ]);

  useEffect(() => {
    if (isLoaded) {
      setIsDownloaded(true);
      setLoadingInfo("Loading Space...");
      sendPing();
    }
  }, [isLoaded]);

  useEffect(() => {
    sendMessage('ServerHandle', 'OnUploadCompleted');
  },[fileState]);

  //TODO: implement this
  function handleOnUploadFile(event: any) {
    setIsUploadFileModalOpen(true);
  }

  //TODO: implement this
  function openPanel() {
    sendMessage('ServerHandle', 'OpenFilePanel');
  }

  async function handleSpaceLoaded() {
  }

  async function handleOnPortalEnter(destinationUuid: any) {
    await fetchAndLeave(destinationUuid);
  }

  //TODO this gives an error, needs to be fixed!
  const fetchAndLeave = async (spaceProUuid : any) => {
    try {
      const response = await getSpaceProWithUuid(spaceProUuid);
      // @ts-ignore
      if (response.status === 200) {
        // @ts-ignore
        unload().then(() => {
          setIsUnityMounted(false);
          setIsVoiceMounted(false);
        }).then(() => {
          // @ts-ignore
          window.location.replace(`/spaces/${response.data.sessionSpaceId}`, "_self");
        });
      } else {
        unload().then(() => {
          errorNotification("InputFieldError loading space");
          navigate("/spaces");
        });
      }
    } catch (error) {
      errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  };

  function handleOnLoadingActionChanged(action : string, progression : number) {
    setLoadingInfo(action);
    setProgression(progression);
  }

  async function handleOnLoadingFinished() {
    await delay(500);
    setIsUnityRavelLoaded(true);
    setIsVoiceMounted(true);
  }

  function handleOnDisconnect() {
    errorNotification("Seems like your network is playing hard to get. Please try again later.");
    returnToSpaces();
  }

  async function sendPing() {
    await delay(500); //TODO replace this ugly delay
    sendMessage("ServerHandle", "UnityPing",);
  }

  async function handleServerPing() {
    setLoadingInfo("Configuring your Space...");
    await sendApiUrl();
    await sendAppMode();
    await sendUser();
    await sendSpacePro();
    await sendRoom();
    await delay(500);
    setLoadingInfo("More additional configurations...");
  }

  function sendApiUrl() {
    sendMessage("ServerHandle", "SetAPIUrl", apiBaseUrl);
  }

  function sendAppMode() {
    if (photonMode === null) {
      errorNotification(`Error loading Space with network mode ${photonMode}`);
      navigate("/spaces");
    }
    // @ts-ignore
    sendMessage("ServerHandle", "SetAppMode", parseInt(photonMode));    }

  function sendUser() {
    sendMessage(
        "ServerHandle",
        "ServerLogin",
        JSON.stringify(currentUser)
    );
  }

  function sendSpacePro() {
    sendMessage("ServerHandle", "SetSpace", JSON.stringify(spacePro));
  }

  function sendRoom() {
    sendMessage("ServerHandle", "SetRoom", JSON.stringify(room));
  }

  function returnToSpaces(){
    unload().then(() => {
      setIsUnityMounted(false);
      setIsVoiceMounted(false);
    }).then(() => {
      window.open("/spaces", "_self");
    });
  }

  //TODO implement sendMouthOpen, WebRTC receives mic audio level and sends it to unity. This will make mouth move
  function sendMouthOpen(amount : number) {
    sendMessage("ServerHandle", "SetAvatarMouthOpen",amount);
  }

  // @ts-ignore
  return (
      <>
        <Room>
          <RoomContent>
            <ControlBar>
              <HeaderWrapper>
                <ModalLeaveSpace leaveSpace={returnToSpaces}/>
              </HeaderWrapper>
            </ControlBar>
          </RoomContent>
        </Room>
        <Fragment>
          <div className="wrapper">
            {isUnityMounted === true && (
                <Fragment>
                  <UnityContainer>
                    {isUnityRavelLoaded === false && (
                        <LoadingOverlay img={spacePro.environmentPro.imageUrl}>
                          <LoaderCirclePrimary/>
                          <p
                              style={{
                                marginTop: "10px",
                                marginLeft: "10px",
                                color: "#c3c8d3",
                              }}
                          >
                            {" "}
                            {loadingInfo}
                          </p>
                          <ProgressBar>
                            <ProgressBarFill
                                style={{width: (loadingProgression * 50) + (progression * 50)  + "%" }}
                            />
                          </ProgressBar>
                        </LoadingOverlay>
                    )}
                    <Unity
                        unityProvider={unityProvider}
                        style={{
                          visibility: isLoaded ? "visible" : "hidden",
                          width: "100%",
                          height: "100%",
                        }}
                    />
                  </UnityContainer>
                </Fragment>
            )}
          </div>
        </Fragment>
      </>
  );
}

export default WebGL;

const BottomBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 20px;
  bottom: 20px;
  width: 100vw;
  position: fixed;
`;

const BottomBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 100px;
  border-radius: 103.5px;
`;

// @ts-ignore
const LoadingOverlay = styled.div<ImageProps>`
  background: linear-gradient(111.43deg, rgba(122, 118, 118, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.img});
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;


const ProgressBar = styled.div`
  position: relative;
  display: inline-block;
  width: 300px;
  height: 10px;
  background: linear-gradient(270deg,
  #e0e5f2 34.37%,
  rgba(255, 255, 255, 0) 100%);
  border-radius: 60px;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 10px;
  background: linear-gradient(270deg,
  #4a4aff 5.56%,
  rgba(74, 74, 255, 0.5) 100%);
  transition: width 0.5s ease;
`;

const ControlBar = styled.div`
  display: grid;
  z-index: 2;
  pointer-events: none !important;
`;

const Room = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none !important;
`;

const RoomContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  align-items: center;
  pointer-events: none !important;
`;

const UnityContainer = styled.div`
  height: 100vh;
  width: 100vw;
  outline: none;
  cursor: none;
  overflow: hidden;
`;

const HeaderWrapper = styled.header`
  display: flex;
  padding: 20px 25px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    padding: 25px 10px;
    justify-content: center;
  }
`;
