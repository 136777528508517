import styled from "styled-components";
import { ButtonChipStyles } from "../../styles/ButtonChipStyles";

const ButtonWrapper = styled.button`
  min-width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;

  color: ${({ hasBackgroundColor, theme }) =>
    hasBackgroundColor
      ? theme.colour.secondary.light
      : theme.colour.primary.purpleBlue500};

  background: ${({ hasBackgroundColor, theme }) =>
    hasBackgroundColor
      ? theme.colour.primary.purpleBlue500
      : theme.colour.secondary.light};

  border: ${({ hasBackgroundColor, isLightOutline, theme }) =>
    hasBackgroundColor
      ? "none"
      : isLightOutline
      ? `1px solid ${theme.colour.primary.purpleBlue100}`
      : `1px solid ${theme.colour.primary.purpleBlue500}`};

  &:hover {
    background-color: ${({ hasBackgroundColor, theme }) =>
      hasBackgroundColor ? theme.colour.primary.purpleBlue700 : "none"};
    color: ${({ hasBackgroundColor, theme }) =>
      !hasBackgroundColor
        ? theme.colour.primary.purpleBlue700
        : theme.colour.secondary.light};
    border: ${({ isLightOutline, theme }) =>
      isLightOutline
        ? `2px solid ${theme.colour.primary.purpleBlue100}`
        : `1px solid ${theme.colour.primary.purpleBlue700}`};
  };

  ${ButtonChipStyles}

}`;

export const ButtonChip = ({
  children,
  className,
  width = 110,
  height = 32,
  hasBackgroundColor = false,
  isLightOutline = false,
  onClick,
}) => {
  return (
    <ButtonWrapper
      className={className}
      width={width}
      height={height}
      hasBackgroundColor={hasBackgroundColor}
      isLightOutline={isLightOutline}
      onClick={onClick}
    >
      <span className="title">{children}</span>
    </ButtonWrapper>
  );
};
