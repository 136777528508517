import client from "../Client";

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const getSpacesForOrganization = async (organizationId) => {
  try {
    const response = await client.get(
        apiUrl + `/api/v1/spaces/pro/spaces?spaceType=ORGANIZATION&organizationUuid=${organizationId}`,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSpacesWithParams = async (spaceType, organizationUUID, eventId) => {
  try {
    const response = await client.get(apiUrl + `/api/v1/spaces/pro/spaces?spaceType=${spaceType}`
        ,
        {
          params : {
            ...(organizationUUID ? { organizationUuid: organizationUUID } : {}),
            ...(eventId ? { eventId: eventId } : {}),
          }
        }
    )
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getSpaces = async (spaceType) => {
  try {
    const response = await client.get(
        apiUrl + `/api/v1/spaces/pro/spaces?spaceType=${spaceType}`,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};


export const getPublicEnvironments = async () => {
  try {
    const response = await client.get(apiUrl + "/api/v1/spaces/env/pro/all");
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSpacePro = async (spaceProId) => {
  try {
    const response = await client.get(
      apiUrl + "/api/v1/spaces/pro/sessions/" + spaceProId
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const deleteSpacePro = async (spaceProUuid) => {
  try {
    const response = await client.delete(
      `${apiUrl}/api/v1/spaces/pro/${spaceProUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSpaceProWithUuid = async (spaceProUuid) => {
  try {
    const response = await client.get(
      apiUrl + "/api/v1/spaces/pro/spaces/url/" + spaceProUuid
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSessionDetails = async (userUuid, spaceProId) => {
  try {
    const response = await client.get(
      apiUrl + "/api/v1/spaces/pro/sessions/" + userUuid + "/" + spaceProId
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getAllSpacesAsSysAdmin = async () => {
  try {
    const response = await client.get(
        `${apiUrl}/api/v1/admin/spaces/pro/all`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const createOrganizationSpace = async (
  spaceName,
  description,
  codeProtected,
  spaceType,
    environmentUuid,
  defaultSpaceRole,
  userUuid,
  organizationName
) => {
  try {
    const response = await client.post(
      apiUrl + "/api/v1/spaces/pro/organizations/",
      {
        spaceName: spaceName,
        description: description,
        codeProtected: codeProtected,
        spaceType: spaceType,
        environmentUuid: environmentUuid,
        defaultSpaceRole: defaultSpaceRole,
        userUuid: userUuid,
        organizationName: organizationName,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getTokenForStreaming = async (
    createForChannel,
    createForUserId,
    agoraRole,
) => {
  try {
    const response = await client.post(
        `${apiUrl}/api/v1/admin/agora/create?agoraRole=${agoraRole}`,
        {
          createForChannel: createForChannel,
          createForUserId: createForUserId,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
