import { ButtonChip } from "../button/ButtonChip";

import React from "react";

function EnvironmentSubmissionActions({
  environment,
  handleOnReviewSubmit,
  handleOnPublish,
}) {
  return (
    <>
      {environment.published ? (
        <></>
      ) : (
        <>
          {environment.qaApproved ? (
            <></>
          ) : (
            <>
              {environment.submissionInProgress ? (
                <></>
              ) : (
                <>
                  <div
                    onClick={() =>
                      handleOnReviewSubmit(environment.environmentUuid)
                    }
                  >
                    <ButtonChip hasBackgroundColor width={128} height={40}>
                      Submit
                    </ButtonChip>
                  </div>
                </>
              )}
            </>
          )}

          {environment.qaApproved ? (
            <>
              <div onClick={() => handleOnPublish(environment.environmentUuid)}>
                <ButtonChip hasBackgroundColor width={128} height={40}>
                  Publish
                </ButtonChip>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
export default EnvironmentSubmissionActions;
