import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Avatar} from 'antd';

export default function Messages({ socket, user, room }) {
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const messageListener = (message) => {
      setMessages((prevMessages) => {
        const newMessages = { ...prevMessages };
        newMessages[message.id] = message;
        return newMessages;
      });
      appendMessage();
    };

    const deleteMessageListener = (messageID) => {
      setMessages((prevMessages) => {
        const newMessages = { ...prevMessages };
        delete newMessages[messageID];
        return newMessages;
      });
    };

    socket.on("message", messageListener);
    socket.on("deleteMessage", deleteMessageListener);
    socket.emit("getMessages");

    return () => {
      socket.off("message", messageListener);
      socket.off("deleteMessage", deleteMessageListener);
    };
  }, [socket]);

  function appendMessage() {
    const el = document.getElementById("chat-feed");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }

  return (
    <>
      {user && (
        <>
          <ChatWrapper>
            <section className="msger">
              <main className="msger-chat" id="chat-feed">
                {[...Object.values(messages)]
                  .sort((a, b) => a.time - b.time)
                  .map((message) => (
                    <>
                      {message.userDetails?.userUUID === user.userUUID ? (
                        <>
                          <div  className="msg right-msg">
                            <div
                              className="msg-img"
                            >
                              <Avatar
                                shape="circle"
                                size={50}
                                src={message.userDetails?.profileImageUrl}
                              />
                            </div>

                            <div className="msg-bubble">
                              <div className="msg-info">
                                <div className="msg-info-name">
                                  {message.userDetails?.firstName}
                                </div>
                                <div className="msg-info-time">
                                  {new Date(message.time).toLocaleTimeString()}
                                </div>
                              </div>

                              <div className="msg-text">{message.value}</div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="msg left-msg">
                            <div
                              className="msg-img"
                            >
                              <Avatar
                                shape="circle"
                                size={50}
                                src={message.userDetails.profileImageUrl}
                              />
                            </div>

                            <div className="msg-bubble">
                              <div className="msg-info">
                                <div className="msg-info-name">
                                  {message.userDetails.firstName}
                                </div>
                                <div className="msg-info-time">
                                  {new Date(message.time).toLocaleTimeString()}
                                </div>
                              </div>

                              <div className="msg-text">{message.value}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
              </main>
            </section>
          </ChatWrapper>
        </>
      )}
    </>
  );
}

const ChatWrapper = styled.div`
  pointer-events: all;
  --msger-bg: #ffffff;
  --border: 1px solid #ffffff;
  --left-msg-bg: #f4f7fe;
  --right-msg-bg: #c9d4ea;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  min-width: 100%;

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
  }

  .msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    height: calc(100%);
    border: var(--border);
    border-radius: 5px;
    background: var(--msger-bg);
  }

  .msger-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: var(--border);
    background: #eee;
    color: #000000;
  }

  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 5px;
  }

  .msger-chat::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  .msger-chat::-webkit-scrollbar-track {
    background: #f4f7fe;
    border-radius: 10px;
  }

  .msger-chat::-webkit-scrollbar-thumb {
    background: #c9d4ea;
    border-radius: 10px;
  }

  .msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }

  .msg:last-of-type {
    margin: 0;
  }

  .msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  .msg-bubble {
    max-width: 240px;
    padding: 15px;
    border-radius: 15px;

    background: var(--left-msg-bg);
  }

  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .msg-info-name {
    margin-right: 10px;
    font-weight: bold;
    font-size: var(--font-size-05);
    color: var(--color-secondary-primary-black);
  }

  .msg-info-time {
    font-size: 0.55em;
    color: #8f9bba;
  }

  .left-msg .msg-bubble {
    border-bottom-left-radius: 0;
  }

  .right-msg {
    flex-direction: row-reverse;
  }

  .right-msg .msg-bubble {
    background: var(--right-msg-bg);
    border-bottom-right-radius: 0;
  }

  .right-msg .msg-img {
    margin: 0 0 0 10px;
  }

  .msger-chat {
    background-color: #ffffff;
  }
`;
