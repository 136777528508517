import { DefaultPageLayoutOpen } from "../../components/layout/LayoutDefaultPageOpen";
import Card from "../../components/cards/Card";
import { CardTitle } from "../../components/cards/CardTitle";
import { CardSubText } from "../../components/cards/CardSubText";
import { Link } from "react-router-dom";
import { ButtonText } from "../../components/button/ButtonText";
import { CardHeaderWrapper } from "../../components/cards/CardHeaderWrapper";
import FormCreateAccountForCreator from "../../components/form/FormCreateAccountForCreator";

function CreateAccountForCreator() {
  return (
    <>
      <DefaultPageLayoutOpen>
        <Card>
          <CardHeaderWrapper>
            <div>
              <CardTitle>Create account</CardTitle>
            </div>
            <div>
              <CardSubText>
                Provide your details to create an account for Ravel
              </CardSubText>
            </div>
          </CardHeaderWrapper>
          <FormCreateAccountForCreator />
          <Link to={"/login"}>
            <ButtonText>Log in</ButtonText>
          </Link>
        </Card>
      </DefaultPageLayoutOpen>
    </>
  );
}

export default CreateAccountForCreator;
