import React, { useCallback, useContext, useEffect, useState } from "react";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import ModalAvatar from "../../components/modal/ModalAvatar";
import { ButtonChip } from "../../components/button/ButtonChip";
import { successNotification } from "../../components/notifications/Notifications";
import { updateAvatarFullBody } from "../../api/services/AccountServices";
import { AuthContext } from "../../providers/AuthContextProvider";
import { AvatarIframe } from "../iframe/IframeAvatarStyle";

function ModalChangeAvatarFullBody({ setUserState }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function closeModal() {
    setIsModalOpen(false);
  }
  const { user } = useContext(AuthContext);

  const uploadAvatarUrl = useCallback(
    async (avatarUrl) => {
      try {
        if (user) {
          await updateAvatarFullBody(user.userUUID, avatarUrl);
          successNotification("Avatar updated successfully");
          setUserState(true);
          closeModal();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [setUserState]
  );

  useEffect(() => {
    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);
    return () => {
      window.addEventListener("message", subscribe);
      document.addEventListener("message", subscribe);
    };
  }, []);

  function subscribe(event) {
    if (event.origin === "https://ravel-fullbody.readyplayer.me") {
      console.log(event.data);
      uploadAvatarUrl(event.data);
    }
  }

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <ButtonChip>Change avatar</ButtonChip>
      </div>
      {isModalOpen && (
        <ModalAvatar
          id="modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          modalSize={"lg"}
        >
          <ModalTitle>Configure your avatar</ModalTitle>
          <ModalText>
            Change the looks of your avatar by following the instructions in the
            screen below
          </ModalText>
          <div className="box-body">
            <AvatarIframe
              id="frame"
              className="frame"
              allow="camera *; microphone *"
              src="https://ravel-fullbody.readyplayer.me/avatar"
            />
          </div>
        </ModalAvatar>
      )}
    </div>
  );
}

export default ModalChangeAvatarFullBody;
