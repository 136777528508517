import { useForm } from "react-hook-form";
import React, { useContext, useState } from "react";

import {
  errorNotification,
  successNotification,
} from "../../components/notifications/Notifications";
import styles from "../../components/form/DefaultForm.modules.css";
import { InputFieldWrapper } from "../../components/form/InputFieldWrapper";
import { InputFieldTitle } from "../../components/form/InputFieldTitle";
import { InputFieldError } from "../../components/form/InputFieldError";
import LoaderCircleSmall from "../../components/loader/LoaderCircleSmall";

import { submitNewEnvironment } from "../../api/services/EnvironmentServices";
import { AuthContext } from "../../providers/AuthContextProvider";

function FormSubmitNewEnvironment({ handleOnEnvironmentCreated }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  async function onSubmit(data) {
    setLoading(true);
    try {
      const response = await submitNewEnvironment(
        user.userUUID,
        data.name,
        data.shortSummary,
        data.longSummary,
        data.public
      );
      if (response.status === 200) {
        setLoading(false);
        successNotification("Environment created successfully");
        handleOnEnvironmentCreated(response.data);
      }
      if (response.status === 400) {
        errorNotification(response.message);
        setLoading(false);
      }
      if (response.status === 403) {
        errorNotification(response.message);
        setLoading(false);
      }
      if (response.status === 409) {
        errorNotification(response.message);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Name</InputFieldTitle>
            <input {...register("name", { required: true })} type="text" />
            {errors.creatorName && (
              <InputFieldError>Name is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Short summary</InputFieldTitle>
            <input
              {...register("shortSummary", { required: true })}
              type="text"
            />
            {errors.creatorBio && (
              <InputFieldError>Short summary is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Long summary</InputFieldTitle>
            <input
              {...register("longSummary", { required: true })}
              type="text"
            />
            {errors.creatorBio && (
              <InputFieldError>Long summary is required</InputFieldError>
            )}
          </InputFieldWrapper>

          <InputFieldWrapper>
            <InputFieldTitle>
              Public or private environment, default is private
            </InputFieldTitle>
            <select {...register("public", { required: true })}>
              <option value="false">Private</option>
              <option value="true">Public</option>
            </select>
          </InputFieldWrapper>

          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Create"}{" "}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormSubmitNewEnvironment;
