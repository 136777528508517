import client from '../Client';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;


export const connectWallet = async ( address, chain ) => {
  try {
    const response = await client.post(
        `${apiUrl}/api/v1/integrations/wallets/connect`,
        {
          address: address,
          chain: chain,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const disconnectWallet = async ( address) => {
  try {
    const response = await client.delete(
        `${apiUrl}/api/v1/integrations/wallets/connect/${address}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};


export const getIntegrations = async (  ) => {
  try {
    const response = await client.get(
        `${apiUrl}/api/v1/integrations`,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
