import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import SpacesSection from "../../components/spaces/SpacesSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";

export const SpacesOverview = () => {
  return (
    <>
      <LayoutDefaultPageClosed>
        <PageDetailsProvider
          title={"Spaces"}
          description={"Spaces overview"}
          meta={""}
        />
        <SpacesSection />
      </LayoutDefaultPageClosed>
    </>
  );
};
