import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import CreatorsSection from "../../components/creators/CreatorsSection";
import { PageDetailsProvider } from "../../providers/PageDetailsProvider";

function CreatorsOverview() {
  return (
    <>
      <LayoutDefaultPageClosed>
        <PageDetailsProvider title={"Creator"} description={""} meta={""} />

        <CreatorsSection />
      </LayoutDefaultPageClosed>
    </>
  );
}

export default CreatorsOverview;
