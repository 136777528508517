import React, { Component } from "react";
import Modal from "../../components/modal/Modal";
import { ModalTitle } from "../../components/modal/ModalTitle";
import { ModalText } from "../../components/modal/ModalText";
import { ButtonChip } from "../../components/button/ButtonChip";
import FormAddUserToOrg from "../form/FormAddUserToOrg";

//TODO refactor to new component
class AddUser extends Component {
  state = {
    isModalOpen: false,
  };

  toggleState = (e) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  render() {
    return (
      <div>
        <div onClick={this.toggleState}>
          <ButtonChip width={140} height={40}>
            Invite user
          </ButtonChip>
        </div>
        {this.state.isModalOpen && (
          <Modal
            id="modal"
            isOpen={this.state.isModalOpen}
            onClose={this.toggleState}
          >
            <ModalTitle>Invite user</ModalTitle>
            <ModalText>
              Provide the email address and select a role for the person you
              want to invite to your organization
            </ModalText>
            <div className="box-body">
              <FormAddUserToOrg organizationName={this.props.selectedOption} />
            </div>

            <div className="box-footer">
              <div onClick={this.toggleState} className="close">
                <ButtonChip width={110} height={32}>
                  Cancel
                </ButtonChip>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default AddUser;
