import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { LayoutDefaultPageClosed } from "../../components/layout/LayoutDefaultPageClosed";
import { SubMenuContainer } from "../../components/sub-menu/SubMenuContainer";
import SubMenuBar from "../../components/sub-menu/SubMenuBar";
import { SubMenuItem } from "../../components/sub-menu/SubMenuItem";
import { ButtonText } from "../../components/button/ButtonText";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../components/notifications/Notifications";
import { ButtonChip } from "../../components/button/ButtonChip";
import { Heading2 } from "../../components/Headings";
import { Paragraph } from "../../components/Paragraphs";
import { ItemHeader } from "../../components/item/ItemHeader";
import { CardContent } from "../../components/cards/CardContent";
import { ItemTitle } from "../../components/item/ItemTitle";
import { ItemContainer } from "../../components/item/ItemContainer";
import { ItemTextSubtile } from "../../components/item/ItemTextSubtile";
import { ItemText } from "../../components/item/ItemText";

import {
  deleteSpacePro,
  getSpaceProWithUuid,
} from "../../api/services/SpaceProServices";

//TODO: add organization owner to space?
//TODO: add online users to space?
//TODO: add user roles for specific space?
//TODO: add roles to details and change default roles?
export const SpaceDetails = () => {
  const { spaceUuid } = useParams();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({});
  const [environment, setEnvironment] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await getSpaceProWithUuid(spaceUuid);
      if (response.status === 200) {
        setFetching(false);
        setData(response.data);
        setEnvironment(response.data.environmentPro);
      }
      if (response.status === 404) {
        setFetching(false);
        navigate("/spaces");
      } else {
        // errorNotification(response.data.message);
      }
    } catch (e) {
      setFetching(false);
      errorNotification(e.message);
    }
  }, []);

  const deleteSpace = useCallback(async () => {
    try {
      const response = await deleteSpacePro(spaceUuid);
      if (response.status === 204) {
        successNotification("Space deleted successfully");
        navigate("/spaces");
      }
      if (response.status === 404) {
        setFetching(false);
      } else {
        // errorNotification(response.data.message);
      }
    } catch (e) {
      setFetching(false);
      errorNotification(e.message);
    }
  }, []);

  useEffect(() => {
    fetchData().then((r) => setFetching(false));
  }, [fetchData]);

  function handleOnDelete() {
    deleteSpace();
  }

  function handleOnPreview() {
    infoNotification("Coming soon");
  }

  function convertDate(date) {
    const d = new Date(date);
    return d.toUTCString();
  }

  //TODO : implement validation user permission for specific space on actions to prevent unauthorized actions by users. Need backend impl of more details about space and specific user so these details are included in the API request
  const renderActions = () => {
    if (data.spaceType === "ORGANIZATION") {
      return (
        <>
          <div onClick={() => handleOnDelete()}>
            <ButtonChip>Delete</ButtonChip>
          </div>
          <div onClick={() => handleOnPreview()}>
            <ButtonChip>Update roles</ButtonChip>
          </div>
          <div onClick={() => handleOnPreview()}>
            <ButtonChip>Create code</ButtonChip>
          </div>
        </>
      );
    }
    if (data.spaceType === "PUBLIC") {
      return <></>;
    }
    if (data.spaceType === "PRIVATE") {
      return (
        <>
          <div onClick={() => handleOnDelete()}>
            <ButtonChip>Delete</ButtonChip>
          </div>
          <div onClick={() => handleOnPreview()}>
            <ButtonChip>Update roles</ButtonChip>
          </div>
          <div onClick={() => handleOnPreview()}>
            <ButtonChip>Create code</ButtonChip>
          </div>
        </>
      );
    }

    return <></>;
  };

  const renderCard = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <GridItemContainer>
          <CardWrapper>
            <EnvImageWrapper>
              <GridItemContent>
                <GridItemLink href={`/spaces/${data.photonRoomId}`}>
                  <GridItemImageMask
                    src={environment.imageUrl}
                    alt={environment.name}
                  ></GridItemImageMask>
                  <GridItemTextContainer></GridItemTextContainer>
                </GridItemLink>
              </GridItemContent>
            </EnvImageWrapper>
            <CardContent>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Space details</Heading2>
                    </ItemTitle>
                    <div></div>
                    <div onClick={() => handleOnPreview()}>
                      <ButtonChip hasBackgroundColor width={128} height={40}>
                        Enter
                      </ButtonChip>
                    </div>
                  </GridHeader>
                </ItemHeader>
                <div>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Name : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{data.spaceName}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Description: </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{data.description}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Space type : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{data.spaceType}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Created at : </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{convertDate(data.created_at)}</Paragraph>
                    </ItemText>
                  </GridBody>
                </div>
              </ItemContainer>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Environment</Heading2>
                    </ItemTitle>
                    <div></div>
                  </GridHeader>
                </ItemHeader>

                <div>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Environment: </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{environment.name}</Paragraph>
                    </ItemText>
                  </GridBody>
                  <GridBody>
                    <ItemTextSubtile>
                      <Paragraph>Short summary: </Paragraph>
                    </ItemTextSubtile>
                    <ItemText>
                      <Paragraph>{environment.description}</Paragraph>
                    </ItemText>
                  </GridBody>
                </div>
              </ItemContainer>
              <ItemContainer>
                <ItemHeader>
                  <GridHeader>
                    <ItemTitle>
                      <Heading2>Actions</Heading2>
                    </ItemTitle>
                  </GridHeader>
                </ItemHeader>
                <div>
                  <ActionsWrapper>{renderActions()}</ActionsWrapper>
                </div>
              </ItemContainer>
            </CardContent>
          </CardWrapper>
        </GridItemContainer>
      </>
    );
  };

  return (
    <>
      <LayoutDefaultPageClosed>
        <SubMenuContainer>
          <SubMenuBar>
            <SubMenuItem>
              <Link to={"/spaces"}>
                <ButtonText>Go back</ButtonText>
              </Link>
            </SubMenuItem>
            <SubMenuItem></SubMenuItem>
            <SubMenuItem></SubMenuItem>
          </SubMenuBar>
        </SubMenuContainer>
        <EnvironmentContainer>
          <EnvironmentOverview>{renderCard()}</EnvironmentOverview>
        </EnvironmentContainer>
      </LayoutDefaultPageClosed>
    </>
  );
};

const EnvironmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 20%;
  //background-color: rgba(67, 24, 255, 0.25);
  //border: 1px solid rgba(67, 24, 255, 0.25);
`;

const GridBody = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  //background-color: rgba(67, 24, 255, 0.25);
  //border: 1px solid rgba(67, 24, 255, 0.25);
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const EnvironmentOverview = styled.div``;

const GridItemContainer = styled.div`
  //height: 600px;
  position: relative;
  width: 600px;
`;

const GridItemContent = styled.div`
  height: 300px;
  width: 100%;
  position: relative;
`;

const GridItemLink = styled.div`
  //display: block;
  width: 100%;
  height: 100%;
`;

const GridItemImageMask = styled.img`
  height: 100%;
  width: 100%;
  //position: absolute;
  bottom: 0;
  background: rgb(255, 255, 255);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  //border-bottom-left-radius: 0px;
  //border-bottom-right-radius: 0px;

  //background-size: cover;
  //background-position: center;
  //background-repeat: no-repeat;
`;

const GridItemTextContainer = styled.div`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  padding: 0 24px 20px 28px;
  text-shadow: 0 0.25rem 0.375rem rgb(0 0 0 / 30%);
`;

const EnvImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //width: 242px;
  align-items: flex-start;
`;

const CardWrapper = styled.div`
  min-height: 400px;
  background: #ffffff;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);

  border-radius: 30px;

  @media only screen and (max-width: 500px) {
    //max-height: 300px;
    //width: 300px;
  }
`;
