import { useForm } from "react-hook-form";
import React, { useContext, useState } from "react";

import {
  errorNotification,
  successNotification,
} from "../notifications/Notifications";
import styles from "../../components/form/DefaultForm.modules.css";
import { InputFieldWrapper } from "./InputFieldWrapper";
import { InputFieldTitle } from "./InputFieldTitle";
import { InputFieldError } from "./InputFieldError";
import LoaderCircleSmall from "../loader/LoaderCircleSmall";
import { CardTitle } from "../cards/CardTitle";
import { CardSubText } from "../cards/CardSubText";
import styled from "styled-components";
import { createNewActiveCreator } from "../../api/services/EnvironmentServices";
import { AuthContext } from "../../providers/AuthContextProvider";

function FormAddUserToOrg(selectedOption) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  async function onSubmit(data) {
    setLoading(true);
    try {
      const response = await createNewActiveCreator(
        user.userUUID,
        data.creatorName,
        data.creatorBio,
        user.email
      );
      if (response.status === 200) {
        setLoading(false);
        successNotification("User invited successfully");
        return response.data;
      }
      if (response.status === 400) {
        errorNotification(response.message);
        setLoading(false);
      }
      if (response.status === 403) {
        errorNotification(response.message);
        setLoading(false);
      }
      if (response.status === 409) {
        errorNotification(response.message);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <>
      <CardHeaderWrapper>
        <div>
          <CardTitle>Request creator account</CardTitle>
        </div>
        <div>
          <CardSubText>
            Fill in your details to request a creator account
          </CardSubText>
        </div>
      </CardHeaderWrapper>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputFieldTitle>Creator name</InputFieldTitle>
            <input
              {...register("creatorName", { required: true })}
              type="text"
            />
            {errors.creatorName && (
              <InputFieldError>Creator name is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputFieldTitle>Creator bio</InputFieldTitle>
            <input
              {...register("creatorBio", { required: true })}
              type="text"
            />
            {errors.creatorBio && (
              <InputFieldError>Creator bio is required</InputFieldError>
            )}
          </InputFieldWrapper>
          <button type="submit">
            {loading ? <LoaderCircleSmall /> : "Request"}{" "}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormAddUserToOrg;

const CardHeaderWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;
