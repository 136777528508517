import { SubMenuContainer } from "../sub-menu/SubMenuContainer";
import styled, { css } from "styled-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SubMenuBar from "../sub-menu/SubMenuBar";
import { SubMenuItem } from "../sub-menu/SubMenuItem";
import { CreatorsRequestAccount } from "./CreatorsRequestAccount";

import { errorNotification } from "../notifications/Notifications";
import {
  getCreatorDetails,
  getEnvironmentsForCreator,
} from "../../api/services/EnvironmentServices";
import { AuthContext } from "../../providers/AuthContextProvider";
import LoaderCircleBig from "../loader/LoaderCircleBig";
import { ButtonChip } from "../button/ButtonChip";
import { Link, useNavigate } from "react-router-dom";
import BadgeSmallGreen from "../badge/BadgeSmallGreen";
import BadgeSmallLightBlue from "../badge/BadgeSmallLightBlue";
import BadgeSmallGrey from "../badge/BadgeSmallGrey";
import BadgeSmallLightGreen from "../badge/BadgeSmallLightGreen";
import BadgeSmallRed from "../badge/BadgeSmallRed";
import TabLeft from "../tabs/TabLeft";
import TabLeftSecondary from "../tabs/TabLeftSecondary";
import TabRight from "../tabs/TabRight";
import TabRightSecondary from "../tabs/TabRightSecondary";

//TODO move higher level components to a higher lever component
function CreatorsSection() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [fetching, setFetching] = useState(true);
  const [creator, setCreator] = useState({});
  const [environments, setEnvironments] = useState([]);

  const [showPublished, setShowPublished] = useState(false);

  const [hasCreatorAccount, setHasCreatorAccount] = useState(false);

  //request to fetch account details from api
  const fetchData = useCallback(async () => {
    try {
      const response = await getCreatorDetails(user.userUUID);
      if (response.status === 200) {
        setFetching(false);
        setCreator(response.data);
        setHasCreatorAccount(true);
        fetchEnvironments(showPublished);
      }
      if (response.status === 404) {
        setFetching(false);
        setHasCreatorAccount(false);
      } else {
        // errorNotification(response.data.message);
      }
    } catch (e) {
      setFetching(false);
      errorNotification(e.message);
    }
  }, []);

  const fetchEnvironments = useCallback(async (isPublished) => {
    try {
      const response = await getEnvironmentsForCreator(
        user.userUUID,
        isPublished
      );
      if (response.status === 200) {
        setFetching(false);
        setEnvironments(response.data);
        setHasCreatorAccount(true);
      } else {
        setEnvironments([]);
        setFetching(false);
        setHasCreatorAccount(false);
      }
    } catch (e) {
      console.error(e);
      errorNotification(e.message);
    }
  }, []);

  useEffect(() => {
    fetchData().then((r) => setFetching(false));
  }, [fetchData]);

  useEffect(() => {
    if (hasCreatorAccount) {
      fetchEnvironments(showPublished);
    }
  }, [showPublished]);

  function handleOnClickShowDraft() {
    setShowPublished(false);
  }

  function handleOnClickShowPublished() {
    setShowPublished(true);
  }

  const renderContent = () => {
    if (fetching) {
      return <LoaderCircleBig></LoaderCircleBig>;
    }
    if (!hasCreatorAccount) {
      return <CreatorsRequestAccount />;
    }
    if (!user.userUUID === creator.creatorUuid) {
      return <div>You are not authorized to view this page</div>;
    }

    return (
      <>
        {" "}
        <SubMenuContainer>
          <SubMenuBar>
            <SubMenuItem>
              <div onClick={handleOnClickShowDraft}>
                {showPublished ? (
                  <TabLeftSecondary>Drafts</TabLeftSecondary>
                ) : (
                  <TabLeft>Drafts</TabLeft>
                )}
              </div>
              <div onClick={handleOnClickShowPublished}>
                {showPublished ? (
                  <TabRight>Published</TabRight>
                ) : (
                  <TabRightSecondary>Published</TabRightSecondary>
                )}
              </div>
            </SubMenuItem>
            <SubMenuItem>
              <div onClick={() => navigate("/creators/new-environment")}>
                <ButtonChip width={140} height={40}>
                  New environment
                </ButtonChip>
              </div>
            </SubMenuItem>
          </SubMenuBar>
        </SubMenuContainer>
        <SpacesContainer>
          <SpaceOverwiew>
            <SpaceList>
              <GridContainer>
                <SpaceGrid>
                  {environments.map((link, index) => (
                    <GridItemContainer key={index}>
                      <CardWrapper>
                        <EnvImageWrapper>
                          <GridItemContent>
                            <GridItemLink>
                              <GridItemImageMask
                                src={
                                  link.metadataPreviewImage
                                    ? link.metadataPreviewImage.url256
                                    : "256x144.png"
                                }
                              ></GridItemImageMask>
                              <GridItemTextContainer></GridItemTextContainer>
                            </GridItemLink>
                          </GridItemContent>
                        </EnvImageWrapper>
                        <CardContent>
                          <CardHeader>
                            <Title>{link.name} </Title>
                            <BadgeSmallGrey>
                              {link.public ? "Public" : "Private"}
                            </BadgeSmallGrey>
                            <div>
                              <div>
                                {link.published ? (
                                  <>
                                    <>
                                      <BadgeSmallGreen>
                                        Published
                                      </BadgeSmallGreen>
                                    </>
                                  </>
                                ) : (
                                  <>
                                    {link.submissionInProgress ? (
                                      <>
                                        <>
                                          <BadgeSmallLightBlue>
                                            In review
                                          </BadgeSmallLightBlue>
                                        </>
                                      </>
                                    ) : (
                                      <>
                                        {link.qaReviewed ? (
                                          <>
                                            {link.qaApproved ? (
                                              <>
                                                <BadgeSmallLightGreen>
                                                  Approved
                                                </BadgeSmallLightGreen>
                                              </>
                                            ) : (
                                              <>
                                                <BadgeSmallRed>
                                                  Declined
                                                </BadgeSmallRed>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <BadgeSmallGrey>
                                              Draft
                                            </BadgeSmallGrey>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </CardHeader>
                          <Description>{link.shortSummary}</Description>
                        </CardContent>
                        <CardFooter>
                          <Link
                            to={
                              "/creators/environments/" + link.environmentUuid
                            }
                          >
                            <ButtonChip>Details</ButtonChip>
                          </Link>
                        </CardFooter>
                      </CardWrapper>
                    </GridItemContainer>
                  ))}
                </SpaceGrid>
              </GridContainer>
            </SpaceList>
          </SpaceOverwiew>
        </SpacesContainer>
      </>
    );
  };

  return <>{renderContent()}</>;
}

export default CreatorsSection;

const SpacesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
`;

const SpaceOverwiew = styled.div`
  width: 60vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const SpaceList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const GridContainer = styled.div`
  height: 100%;
`;

const SpaceGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  row-gap: 40px;
  column-gap: 40px;
  width: 100%;
`;

const GridItemContainer = styled.div`
  height: 300px;
  position: relative;
  width: 300px;
`;

const GridItemContent = styled.div`
  height: 150px;
  width: 100%;
  position: relative;
`;

const GridItemLink = styled.a`
  //display: block;
  width: 100%;
  height: 100%;
`;

const GridItemImageMask = styled.img`
  height: 100%;
  width: 100%;
  bottom: 0;
  background: rgb(255, 255, 255);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const GridItemTextContainer = styled.div`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  padding: 0 24px 20px 28px;
  text-shadow: 0 0.25rem 0.375rem rgb(0 0 0 / 30%);
`;

const EnvImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //width: 242px;
  align-items: flex-start;
`;

const CardWrapper = styled.div`
  height: 300px;
  transition: 0.5s all;
  transform: scale(1);
  background: #ffffff;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);

  border-radius: 30px;

  &:hover {
    transform: scale(1.1);
    transition: 0.5s all; //var(--emo-out);
    box-shadow: 0 16px 48px 0 rgb(0 0 0 / 24%);
  }

  @media only screen and (max-width: 500px) {
    //max-height: 300px;
    //width: 300px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  //width: 280px;
  height: 10px;
  padding: 5px;
  padding-right: 25px;
  //margin-top: auto;
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 280px;
  height: 75px;
  padding: 10px;
  align-items: flex-start;
`;

const Title = styled.title`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: var(--font-size-20);
    font-weight: ${theme.font.weight.bold700};
    font-style: normal;
    line-height: 16px;
    /* identical to box height */
    letter-spacing: -0.531474px;
    color: var(--color-secondary-primary-black);
    //margin-left: var(--space-40);
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    padding-left: 7px;
    padding-bottom: 3px;
  `}
`;
const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: var(--font-size-10);
    font-weight: ${theme.font.weight.regular400};
    font-style: normal;
    line-height: 16px;
    /* identical to box height */
    letter-spacing: -0.531474px;
    color: var(--color-secondary-primary-black);
    //margin-left: var(--space-40);
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    //width: 200px;
    max-height: 50px;
    //white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 7px;
    padding-right: 7px;
  `}
`;
