import styled from "styled-components";
import Card from "../cards/Card";
import FormRequestCreatorAccount from "../form/FormRequestCreatorAccount";

export const CreatorsRequestAccount = () => {
  return (
    <>
      <Card>
        <CardContentWrapper>
          <FormRequestCreatorAccount />
        </CardContentWrapper>
      </Card>
    </>
  );
};

const CardContentWrapper = styled.div`
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
`;
