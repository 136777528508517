import React, { useContext, useEffect } from "react";
import { useDVCClient, useVariableValue } from "@devcycle/devcycle-react-sdk";
import { AuthContext } from "../providers/AuthContextProvider";

export function FlaggedFeatureSocketsChat({ children }) {
  const { user } = useContext(AuthContext);
  const dvcClient = useDVCClient();

  const newUser = {
    user_id: user.userUUID,
  };

  const identifyUser = () => {
    dvcClient
      .identifyUser(newUser)
      .then((variables) => console.log("Updated Variables:", variables));
  };

  const variableKey = "sockets-chat";
  const featureVariable = useVariableValue(variableKey, false);

  useEffect(() => {
    identifyUser();
  }, []);

  return <div>{featureVariable ? <>{children}</> : <></>}</div>;
}
