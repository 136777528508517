import React, { Component } from "react";
import { ButtonText } from "../button/ButtonText";
import Modal from "./Modal";
import { ButtonChip } from "../button/ButtonChip";
import { FormPairDevice } from "../form/FormPairDevice";
import { ModalTitle } from "../modal/ModalTitle";
import { ModalText } from "../modal/ModalText";
// import styled from "styled-components";
// import { ButtonTextStyles } from "../../styles/ButtonTextStyles";

//TODO refactor to new component
class PairDevice extends Component {
  state = {
    isModalOpen: false,
  };

  toggleState = (e) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  render() {
    return (
      <div>
        <span style={{ display: "flex" }} onClick={this.toggleState}>
          <ButtonText> Pair VR device </ButtonText>
        </span>
        {this.state.isModalOpen && (
          <Modal
            id="modal"
            isOpen={this.state.isModalOpen}
            onClose={this.toggleState}
          >
            <div className="box-header">
              <ModalTitle>Pair VR device</ModalTitle>
              <ModalText>Enter the code displayed on your VR device </ModalText>
            </div>
            <div className="box-body">
              <FormPairDevice />
            </div>
            <div className="box-footer">
              <div onClick={this.toggleState} className="close">
                <ButtonChip hasBackgroundColor>Done</ButtonChip>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default PairDevice;

// const ButtonText = styled.button`
//   ${ButtonTextStyles}
//   min-width: 110px;
//   min-height: 32px;
//   font-size: 14px;
//   line-height: 24px;
//   text-align: start;
//   justify-content: start;
// `;
