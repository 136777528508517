import styled, { css } from "styled-components";
import { ReactComponent as Options } from "../../assets/icons/options-button.svg";
import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";

const ButtonWrapper = styled.button`
  ${({ theme }) => css`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background: ${theme.colour.backgroundLight};
    border: ${({ hasBackground }) =>
      hasBackground
        ? `1px solid ${theme.colour.primary.purpleBlue100}`
        : "none"};

    &:hover {
      background: ${({ hasBackground }) =>
        hasBackground ? theme.colour.secondary.grey300 : "none"};
    }

    &:focus {
      border: ${({ hasBackground }) =>
        hasBackground
          ? `2px solid ${theme.colour.primary.purpleBlue700}`
          : "none"};
    }
  `}
`;

const ICONS = {
  options: Options,
  close: Close,
  download: Download,
  trash: Trash,
};

export const ButtonIcon = ({
  className,
  onClick,
  width = 32,
  height = 32,
  icon,
  hasBackground = false,
}) => {
  if (!icon) return null;

  const Icon = ICONS[icon];

  return (
    <ButtonWrapper
      className={className}
      onClick={onClick}
      width={width}
      height={height}
      hasBackground={hasBackground}
    >
      <Icon />
    </ButtonWrapper>
  );
};
