import React, { useContext } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import ResetPassword from "./pages/reset-password/ResetPassword";
import SignUp from "./pages/signup/SignUp";
import ProfileOverview from "./pages/profile/ProfileOverview";
import ContentOverview from "./pages/content/ContentOverview";
import {
  AuthContextProvider,
  AuthContext,
} from "./providers/AuthContextProvider";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import { SpacesOverview } from "./pages/spaces/SpacesOverview";
import LogIn from "./pages/login/LogIn";
import CreateAccount from "./pages/signup/CreateAccount";
import WebGLViewer from "./pages/spaces/WebGLViewer";
import CreatorsOverview from "./pages/creators/CreatorsOverview";
import CreatorsEnvironmentDetails from "./pages/creators/CreatorsEnvironmentDetails";
import { CreatorsNewEnvironment } from "./pages/creators/CreatorsNewEnvironment";
import { SpaceDetails } from "./pages/spaces/SpacesDetails";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AgoraRTC from "agora-rtc-sdk-ng";
import { CreatorsSignUp } from "./pages/creators/CreatorsSignUp";
import CreateAccountForCreator from "./pages/signup/CreateAccountForCreator";
import ContactsOverview from "./pages/contacts/ContactsOverview";
import WebGLPreview from "./pages/creators/WebGLViewer";
import { IntegrationsOverView } from "./pages/integrations/IntegrationsOverView";
import StreamingDashboard from "./pages/streaming/StreamingDashboard";
import StreamingDashboardAudience from "./pages/streaming/StreamingDashboardAudience";
import {SocketAdminDashboard} from './pages/SocketAdminDashboard';
import {SpacesEventSection} from './components/spaces/SpacesEventSection';
import {EventSpacesOverview} from './pages/events/EventSpacesOverview';
import {EventBlendedRealities} from './pages/events/EventBlendedRealities';



const agoraLogLevel = process.env.REACT_APP_SYSTEMS_AGORA_LOG_LEVEL;

function PrivateRoute({ children }) {
  const { isAuth } = useContext(AuthContext);
  return isAuth ? children : <Navigate to="/login" />;
}

function App() {
  AgoraRTC.setLogLevel(agoraLogLevel);

  Sentry.init({
    dsn: "https://d6a9ad5d61e04ca29ece0aa345e3f8a1@o1132931.ingest.sentry.io/6386458  ",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <SentryRoutes>
          <Route
            path="/reset-password/:resetPasswordCode"
            element={<ResetPassword />}
          />
          <Route
            path="/spaces/:spaceId"
            element={
              <PrivateRoute>
                <WebGLViewer />
              </PrivateRoute>
            }
          />
          {/*<Route*/}
          {/*    path="/spaces/custom/hhc/:spaceId"*/}
          {/*    element={*/}
          {/*      <PrivateRoute>*/}
          {/*        <WebGlSectionHhc />*/}
          {/*      </PrivateRoute>*/}
          {/*    }*/}
          {/*/>*/}
          <Route path="/login" element={<LogIn />} />
          <Route path="/blendedrealities" element={<EventBlendedRealities />} />

          <Route path="/socket/admin/:room" element={<SocketAdminDashboard />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/creators/signup" element={<CreatorsSignUp />} />
          <Route path="/create-account/:token" element={<CreateAccount />} />
          <Route
            path="/creators/create-account/:token"
            element={<CreateAccountForCreator />}
          />

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <ProfileOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/spaces"
            element={
              <PrivateRoute>
                <SpacesOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/streaming"
            element={
              <PrivateRoute>
                <StreamingDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/streaming/audience"
            element={
              <PrivateRoute>
                <StreamingDashboardAudience />
              </PrivateRoute>
            }
          />
          <Route
            path="/spaces/details/:spaceUuid"
            element={
              <PrivateRoute>
                <SpaceDetails />
              </PrivateRoute>
            }
          />
          <Route
              path="/events/:eventId"
              element={
                <PrivateRoute>
                  <EventSpacesOverview />
                </PrivateRoute>
              }
          />
          <Route
            path="/content"
            element={
              <PrivateRoute>
                <ContentOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/contacts"
            element={
              <PrivateRoute>
                <ContactsOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/creators"
            element={
              <PrivateRoute>
                <CreatorsOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/creators/new-environment"
            element={
              <PrivateRoute>
                <CreatorsNewEnvironment />
              </PrivateRoute>
            }
          />
          <Route
            path="/creators/environments/:environmentUuid"
            element={
              <PrivateRoute>
                <CreatorsEnvironmentDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/creators/environments/preview/:environmentUuid"
            element={
              <PrivateRoute>
                <WebGLPreview />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <ProfileOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/integrations"
            element={
              <PrivateRoute>
                <IntegrationsOverView />
              </PrivateRoute>
            }
          />
        </SentryRoutes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
