import { useIsDVCInitialized, withDVCProvider } from '@devcycle/devcycle-react-sdk'
import LoaderCirclePrimary from '../components/loader/LoaderCircleBig';
import React from 'react';

const envKey = process.env.REACT_APP_SYSTEMS_DEV_CYCLE_KEY

function DevCycle({children}) {
  const dvcReady = useIsDVCInitialized()

  if (!dvcReady) return (<LoaderCirclePrimary />)
  return (
      <>
        {children}
      </>
  )
}

export default withDVCProvider({ envKey: envKey })(DevCycle)
