import {useEffect, useState} from "react";
import AgoraRTC, {
  CameraVideoTrackInitConfig,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  MicrophoneAudioTrackInitConfig,
} from "agora-rtc-sdk-ng";
import {infoNotification, warningNotification} from "../../../components/notifications/Notifications";

export default function useAgora(client: IAgoraRTCClient | undefined, socket: any , updateUserState: any): {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  joinState: boolean;
  audioEnabled: boolean;
  videoEnabled: boolean;
  leave: Function;
  join: Function;
  handleJoinState: Function;
  handleAudioMute: Function;
  handleVideoMute: Function;
  getMics: Function;
  getCameras: Function;
  switchMicrophone: Function;
  switchCamera: Function;
  remoteUsers: IAgoraRTCRemoteUser[];
  forceAudioMute: Function;
  forceCamMute: Function;
} {

  const [localVideoTrack, setLocalVideoTrack] = useState<
    ILocalVideoTrack | undefined
  >(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<
    ILocalAudioTrack | undefined
  >(undefined);
  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const localTrackState = {
    videoTrackMuted: false,
    audioTrackMuted: false,
  };
  const [microphoneAudioTrack, setMicrophoneAudioTrack] = useState<
      IMicrophoneAudioTrack | undefined>(undefined);
  const [webCamVideoTrack, setWebCamVideoTrack] = useState<
      ICameraVideoTrack | undefined>(undefined);

  const [audioEnabled, setAudioEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [localAudioTrackCreated, setAudioTrackCreated] = useState(false);
  const [localVideoTrackCreated, setVideoTrackCreated] = useState(false);

  async function createLocalVideoTrack(
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<ICameraVideoTrack> {
    const cameraTrack = await AgoraRTC.createCameraVideoTrack({
      encoderConfig: "240p_3",
    });

    setLocalVideoTrack(cameraTrack);
    setWebCamVideoTrack(cameraTrack);
    return cameraTrack;
  }

  async function getMics() {
    const mics = await AgoraRTC.getMicrophones();
    return mics;
  }

  async function getCameras() {
    const cameras = await AgoraRTC.getCameras();

    return cameras;
  }

  async function createLocalMicTrack(
    audioConfig?: MicrophoneAudioTrackInitConfig
  ): Promise<IMicrophoneAudioTrack> {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
      encoderConfig: "high_quality",
      AEC: true,
      AGC: true,
      ANS: true,
    });
    setMicrophoneAudioTrack(microphoneTrack);
    setLocalAudioTrack(microphoneTrack);
    return microphoneTrack;
  }

  async function join(
    appid: string,
    channel: string,
    token: string,
    uid: string | number | null
  ) {
    updateUserState("currentState", "Joining Agora channel" );
    if (!client) return;
    await client.join(appid, channel, token, uid);
    (window as any).client = client;
    (window as any).videoTrack = undefined;
    setJoinState(true);
    localTrackState.audioTrackMuted = true;
    localTrackState.videoTrackMuted = true;
    setAudioEnabled(false);
    setVideoEnabled(false);
    updateUserState("currentState", "Ready & connected to Agora" );
  }

  async function joinVideo() {
    updateUserState("currentState", "Joining Agora video" );

    if (!client) return;
    const cameraTrack = await createLocalVideoTrack();
    await client.publish(cameraTrack);
    localTrackState.videoTrackMuted = false;
    setVideoEnabled(true);
    setVideoTrackCreated(true);
    updateUserState("currentState", "Ready & connected to Agora" );
    updateUserState("videoMuted", false );

  }

  async function joinAudio() {
    updateUserState("currentState", "Joining Agora audio" );
    if (!client) return;
    const microphoneTrack = await createLocalMicTrack();
    await client.publish(microphoneTrack);
    localTrackState.audioTrackMuted = false;
    setAudioEnabled(true);
    setAudioTrackCreated(true);
    updateUserState("currentState", "Ready & connected to Agora");
    updateUserState("muted", false );
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    setAudioEnabled(false);
    setVideoEnabled(false);
    await client?.leave();
  }

  function handleJoinState(
    appid: string,
    channel: string,
    token: string,
    uid: string | number | null
  ) {
    if (!joinState) {
      join(appid, channel, token, uid);
    } else {
      leave();
    }
  }

  function forceAudioMute() {
    if(audioEnabled){
      muteAudio().then(() => {
        infoNotification("Host muted your microphone");
      });
    }
  }

  function forceCamMute() {
    if(videoEnabled){
      muteVideo().then(() => {
        infoNotification("Host muted your webcam");
      });
    }
  }

  function handleAudioMute() {
    if (!localAudioTrackCreated) {
      if(remoteUsers.filter((user) => user.hasAudio).length < 12) {
        joinAudio();

      } else {
        warningNotification("No more than 12 participants can enable audio")
      }
    }
    if (audioEnabled) {

      muteAudio();
    } else {
      if(remoteUsers.filter((user) => user.hasAudio).length < 12) {
        unmuteAudio();
      } else {
        warningNotification("No more than 12 participants can enable audio")
      }
    }
  }

  function handleVideoMute() {
    if (!localVideoTrackCreated) {
      if(remoteUsers.filter((user) => user.hasVideo).length < 6) {
        joinVideo();
      } else {
        warningNotification("No more than 6 participants can enable video")
      }
    } else {
      if (videoEnabled) {
        muteVideo();
      } else {
        if(remoteUsers.filter((user) => user.hasVideo).length < 6) {
          unmuteVideo();
        } else {
          warningNotification("No more than 6 participants can enable video")
        }
      }
    }
  }

  async function switchCamera(deviceId: string) {
    if (!webCamVideoTrack) return;
    webCamVideoTrack.setDevice(deviceId).then(() => {
    }).catch(e => {
      console.log("set device error", e);
    });
  }

  async function switchMicrophone(deviceId: string) {
    if (!microphoneAudioTrack) return;
    microphoneAudioTrack.setDevice(deviceId).then(() => {
    }).catch(e => {
      console.log("set device error", e);
    });
  }




  async function muteAudio() {
    if (!localAudioTrack) return;
    await localAudioTrack.setEnabled(false);
    localTrackState.audioTrackMuted = true;
    setAudioEnabled(false);
    infoNotification("Microphone disabled");
    updateUserState("muted", true );
  }

  async function unmuteAudio() {
    if (!localAudioTrack) return;
    await localAudioTrack.setEnabled(true);
    localTrackState.audioTrackMuted = false;
    setAudioEnabled(true);
    infoNotification("Microphone enabled");
    updateUserState("muted", false );
  }

  async function muteVideo() {
    if (!localVideoTrack) return;
    infoNotification("Webcam disabled");
    await localVideoTrack.setEnabled(false);
    localTrackState.videoTrackMuted = true;
    setVideoEnabled(false);
    updateUserState("videoMuted", true );
  }

  async function unmuteVideo() {
    if (!localVideoTrack) return;
    infoNotification("Webcam enabled");
    await localVideoTrack.setEnabled(true);
    localTrackState.videoTrackMuted = false;
    setVideoEnabled(true);
    updateUserState("videoMuted", false );
  }

  useEffect(() => {

    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);
    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    audioEnabled: audioEnabled,
    videoEnabled: videoEnabled,
    leave,
    join,
    handleJoinState,
    handleAudioMute,
    handleVideoMute,
    getMics,
    getCameras,
    switchMicrophone,
    switchCamera,
    remoteUsers,
    forceAudioMute,
    forceCamMute
  };
}
