import axios from 'axios';


export const getWebGLBuildConfig = async (environmentUuid) => {
  try {
    const response = await axios.get(
        `https://dev.ravel.systems/api/v1/dev-tools/config/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
