import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthContextProvider";
import { errorNotification } from "../../components/notifications/Notifications";

export default function WebSocketWebGL({ room, socketio }) {
  // const socketio = useSocket();
  const { user } = useContext(AuthContext);
  const [moderationUserState, setModerationUserState] = useState({
    muted: false,
    overrideMuteEveryone: false,
    videoMuted: true,
    overRideVideoMuteEveryone: false,
    raisingHand: false,
    currentState: "Not connected",
  });
  const [moderationRoomState, setModerationRoomState] = useState({
    allowSeating: false,
    muteEveryone: true,
    raiseHandEnabled: false,
    forcedSeats: false,
  });

  const handleUserState = useCallback((changes) => {
    console.log("handleUserState", changes);
    setModerationUserState((prevState) => {
      return {
        ...prevState,
        ...changes,
      };
    });
  }, []);

  const handleState = useCallback((changes) => {
    setModerationRoomState(changes);
  }, []);

  const handleStateUpdate = useCallback((changes) => {
    console.log("handleStateUpdate", changes);
    setModerationRoomState((prevState) => {
      return {
        ...prevState,
        ...changes,
      };
    });
  }, []);

  const handleRoomStateUpdateAfterJoin = useCallback((changes) => {
    console.log("ReceiveSTATE AFTER JOIN");
    console.log(changes);
  }, []);

  useEffect(() => {
    //emit when component mounts
    socketio.emit("joined", { room: room.photonRoomId, user: user });
    socketio.on("roomStateAfterJoin", handleRoomStateUpdateAfterJoin);

    // socket to receive user state
    socketio.on("userState", handleUserState);

    // socket to receive entire state of room
    socketio.on("state", handleState);

    // socket when particular state of room is changed
    socketio.on("update", handleStateUpdate);
    // console.log("SOCKET: no socket in WebSocketWebGL.js", socketio)

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socketio.off("userState", handleUserState);
      socketio.off("roomStateAfterJoin", handleRoomStateUpdateAfterJoin);
      socketio.off("state", handleState);
      socketio.off("update", handleStateUpdate);
    };
  }, [
    socketio,
    handleUserState,
    handleState,
    handleStateUpdate,
    handleRoomStateUpdateAfterJoin,
  ]);

  function socketEmitRaiseHand(value) {
    const data = {
      userState: {
        raisingHand: value,
      },
      room: room.photonRoomId,
    };
    socketio.emit("toggleRaiseHand", data);
  }

  function socketUpdateCurrentState(value) {
    const data = {
      userState: {
        currentState: value,
      },
      room: room.photonRoomId,
    };
    socketio.emit("updateCurrentState", data);
  }

  function socketEmitMuteState(value) {
    // const data ={
    //   userState: {
    //     muted: value,
    //   },
    //   room: room.photonRoomId,
    // }
    // socketio.emit("toggleMute2", data);
    errorNotification("Using toggleMute2!!!!!!: " + value);
  }

  function updateUserState(stateType, stateValue) {
    if (stateType === "muted") {
      const userSate = {
        muted: stateValue,
      };
      const data = {
        userState: userSate,
        room: room.photonRoomId,
      };
      socketio.emit("updateCurrentState", data);
    }
    if (stateType === "videoMuted") {
      const userSate = {
        videoMuted: stateValue,
      };
      const data = {
        userState: userSate,
        room: room.photonRoomId,
      };
      socketio.emit("updateCurrentState", data);
    }
    if (stateType === "raiseHand") {
    }
    if (stateType === "currentState") {
      const userSate = {
        currentState: stateValue,
      };
      const data = {
        userState: userSate,
        room: room.photonRoomId,
      };
      socketio.emit("updateCurrentState", data);
    }
  }

  return {
    moderationRoomState,
    moderationUserState,
    socketEmitRaiseHand,
    socketEmitMuteState,
    socketUpdateCurrentState,
    updateUserState,
  };
}
